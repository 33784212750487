
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

html {
  scroll-behavior: smooth!important;
}

body{
    background: #f2f2f2!important;
    font-family: 'Poppins', sans-serif!important;
}

.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:30rem;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.about1 header h1{
 font-size:1.5rem;
  color:#FFFFFF;
  margin-top: 1rem;
text-align:center;
text-transform: uppercase;
letter-spacing: 2px;
font-weight: bolder;
  
}

.about .row {
  
    padding:0!important;
    margin:0!important;
   
}
.about1{
  min-height: 100%!important;
}

@media only screen and (max-width: 1400px) {
.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:25rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

header h1{
 font-size:1.5rem!important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 2px!important;
text-transform: uppercase!important;
font-weight: bolder!important;
  
}}
@media only screen and (max-width: 1200px) {
.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:20rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

header h1{
 font-size:1.8rem!important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 2px!important;
text-transform: uppercase!important;
font-weight: bolder!important;
  
}}
@media only screen and (max-width: 922px) {
.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:20rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

header h1{
 font-size:2rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 2px!important;
font-weight: bolder!important;
  
}}
@media only screen and (max-width: 768px) {
.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:20rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.about1 header h1{
 font-size:1.2rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 1.5px!important;
font-weight: bolder!important;
  
}}
@media only screen and (max-width: 567px) {
  
.about1 header{
  

  max-width: 100%!important;
  background-color: black!important;
  height:16rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.about1 header h1{
 font-size:0.9rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 2px!important;
font-weight: bolder!important;
  
}
}

header h1{
text-transform: uppercase!important;
}























































