@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

html {
  scroll-behavior: smooth;
}

body{
    background:white;
    font-family: 'Poppins', sans-serif;
}


.privacy-cover span{
display:block;
margin-top:0.3rem;
font-weight: 400;
}
.privacy-cover{
    margin: 1rem !important;
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.privacy-content {
    width:80% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
}

@media only screen and (max-width: 1400px) {
    .privacy-content span{
    font-size: 0.90rem !important;
    font-weight: 250!important;
    line-height: 1.3rem !important;
}
.privacy-content {
    width:75% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
}
.privacy-content h3 {
font-size: 1.3rem !important;
}
}
@media only screen and (max-width: 1200px) {
    .privacy-content span{
    font-size: 0.80rem !important;
    font-weight: 250!important;
    line-height: 1.3rem !important;
}
.privacy-content {
    width:80% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}
.privacy-content h3 {
font-size: 1.2rem !important;
margin-top: 0.5rem !important;
}
}
@media only screen and (max-width: 922px) {
    .privacy-content span{
    font-size: 0.75rem !important;
    font-weight: 200!important;
    line-height: 1.2rem !important;
}
.privacy-content {
    width:95% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}
.privacy-content h3 {
font-size: 1.2rem !important;
margin-top: 0.5rem !important;
}
}
@media only screen and (max-width: 768px) {
    .privacy-content span{
    font-size: 0.75rem !important;
    font-weight: 250!important;
    line-height: 1.3rem !important;
}
.privacy-content {
    width:90% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}
.privacy-content h3 {
font-size: 1.2rem !important;
margin-top: 0.5rem !important;
}
}
@media only screen and (max-width: 567px) {

.privacy-content span{
    font-size: 0.75rem !important;
    font-weight: 200!important;
    line-height: 1.5rem !important;
}
.privacy-content {
    width:95% !important;
}
.privacy-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}
.privacy-content h3 {
font-size: 1.2rem !important;
margin-top: 0.5rem !important;
}
}