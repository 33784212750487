@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  
  font-family: "Poppins", sans-serif!important;
}
.freq-cover h6{
text-transform: uppercase;
font-weight:600!important;
}
.freq-cover p{
text-align: left;
}
.freq-cover {
    background-color: #ffff;
    
}
.service-provider{
    width: 80% !important;
    margin: auto;
}
.freq-cover h6:hover,
.freq-cover h6:focus {
  color: #FFC000;
}


a:hover {
  transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
  background-position: 0%;
}
.breadcrumb-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 104px 0 110px;
    position: relative;
    width:100%;
}
.breadcrumb-image::before {
    background: rgba(46, 38, 1, 0.8) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.breadcrumb-image h1 {
    color: #ffff;
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
}
.breadcrumbs_path {
      color: #ffff;
    margin-top: 8px;
    position: relative;
    z-index: 9;
}
.breadcrumbs_path > a {
      color: #ffff;
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 1400px) { .frequent-cover p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .frequent-cover p{
        font-size: 0.80rem!important;
    }
}
@media only screen and (max-width: 922px) { .frequent-cover p{
        font-size: 0.80rem!important;

    }
    .frequent-cover h6{
     text-align: center;
      font-size: 1.5rem!important;
      font-weight: 400!important;
      
 }  
 .frequent-cover h2{
     text-align: center;
      font-size: 1rem!important;
      font-weight: 400!important;
      
 } 
 .breadcrumb-image h1 {
    color: #ffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
}
.service-provider{
    max-width:70%!important;
    margin: auto;
}
}
@media only screen and (max-width: 768px) {
     .frequent-cover p{
        font-size: 0.75rem!important;
    }
}

@media only screen and (max-width: 567px) {
    .service-cover p{
        font-size: 0.75rem!important;
    }
    .frequent-cover h2{
        text-align: center;
    }
}