@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

html {
  scroll-behavior: smooth !important;
}

body {
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}
.section-benefit{
  min-width: 100%!important;

}

.section-benefit .col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.section-benefit .bg-right {
  background-image: url("../img/app-req2.jpg");
  background-position: 80% 20% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: linear-gradient(rgba(0, 0, 0, 0.7)) !important;
  overflow-x: hidden !important;
}
.section-benefit .row {
  min-height: 100vh !important;
}

 .section-benefit .introCov {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #ffc000!important;
}

.section-benefit p {
  font-size: 1rem;

  text-align: center;
  font-weight: 100;
}

.section-benefit span {
  font-size: 1rem;

  text-align: left;
}

.section-benefit .intro {
  margin-left: auto;
  margin-right: auto ;
  width: 55%;
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media only screen and (max-width: 1400px) {
  .section-benefit h5 {
    font-size: 1.4rem !important;

    color: black !important;
    font-weight: bolder !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: 0;
  }
  .section-benefit span {
    font-size: 0.9rem !important;
    text-align: left !important;
  }
  .section-benefit .row {
    min-height: 100vh !important;
  }

  .section-benefit .intro {
    margin-left: auto !important;
    margin-right: auto !important;
   
  }
  .section-benefit .intro ul {
    font-size: 1rem !important;
  }
  .section-benefit {
    padding-bottom: 2rem !important;
  }
}

/* X-Large devices (large desktops, 1200px and up)*/
@media only screen and (max-width: 1200px) {
  .section-benefit h5 {
    font-size: 1.2rem !important;

    color: black !important;
    font-weight: bolder !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: 0;
  }
  .section-benefit span {
    font-size: 0.9rem !important;
    text-align: left !important;
  }
  .section-benefit .row {
    min-height: 100vh !important;
  }

  .section-benefit .intro {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 55% !important;
  }
  .section-benefit .intro ul {
    font-size: 0.9rem !important;
  }
  .section-benefit {
    padding-bottom: 2rem !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 992px) {
  .section-benefit h5 {
    font-size: 1.2rem !important;

    color: black !important;
    font-weight: bolder !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: 0;
  }
  .section-benefit span {
    font-size: 0.8rem !important;

    text-align: left !important;
  }

  .section-benefit .row {
    min-height: 115vh !important;
  }

  .section-benefit .intro {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 55% !important;
  }
  .section-benefit .intro ul {
    font-size: 0.9rem !important;
  }
  .section-benefit {
    padding-bottom: 2rem !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .section-benefit h5 {
    font-size: 1.1rem !important;

    color: black !important;
    font-weight: bolder !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: 0;
  }
  .section-benefit span {
    font-size: 0.8rem !important;

    text-align: left !important;
  }
  .section-benefit .row {
    min-height: 100vh !important;
  }
 .section-benefit .bg-right {
    background-position: 50% 50% !important;
  }
  .section-benefit .intro {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
   
  }
  .section-benefit .intro ul {
    font-size: 0.85rem !important;
  }
  .section-benefit {
    padding-bottom: 2rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .section-benefit p {
    font-size: 1rem !important;

    text-align: left !important;
  }
  .section-benefit img {
    width: 100% !important;
  }
  .section-benefit .row {
    height: 170vh !important;
  }
  .section-benefit .intro {
    width: 70% !important;
  }
  .section-benefit .intro h5 {
    font-size: 1rem !important;
text-align: center;
    color: black !important;
    font-weight: bolder !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: 0;
  }
  .section-benefit .introCov {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-benefit .intro ul {
    font-size: 0.80rem !important;
  }
  .section-benefit {
    padding-bottom: 1.5rem !important;
  }
}
