@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.why {
  margin-top: 1rem;
  min-height: 100vh!important;
  color: black!important;
  text-align: center!important;
  display: flex!important;
  align-items: center!important;
}
.why{
  min-height:100vh!important;
}
 .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem;
    color: "#FFC000"!important;

    font-weight: bolder!important;
    font-size: 1.3rem;
  }
  .whyButton .btn {
    height: 2.7rem;
    width: 12rem;
    border-radius: 4rem;
    margin: 0.2rem;
    font-size: 0.85rem;
    text-align: center!important;
    background-color: #ffc000!important;
  }
.stand {
  margin: auto;
  width: 35%;
}

.stand h4 {
  font-size: 1.2rem;
  text-align: center;
}

.stand h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.3rem;
    margin-bottom: -1.10rem;
  }
  .stand p {
    font-size:0.85rem;
  }



@media only screen and (max-width: 1400px) {
  .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    color: "#FFC000"!important;

    font-weight: bolder!important;
    font-size: 1.3rem !important;
  }
  .whyButton .btn {
    height: 2.7rem!important;
    width: 12rem!important;
    border-radius: 4rem!important;
    margin: 0.3rem!important;
    font-size: 0.85rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .stand {
    width: 35%!important;
  }
  .stand h4 {
    font-size: 0.83rem!important;
    font-weight: bold!important;
    margin: 0.25rem!important;
    margin-bottom: -1.20rem!important;
  }
  .stand p {
   font-size: 0.85rem!important;
  }
}

@media only screen and (max-width: 1200px) {
  .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    font-weight: bolder!important;
    font-size: 1.2rem !important;
  }
  .whyButton .btn {
    height: 2.4rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 0.3rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .stand {
    width: 35%!important;
  }
  .stand h4 {
    font-size: 0.75rem!important;
    font-weight: bolder!important;
    margin-top: 0.25rem!important;
    margin-bottom: -0.90rem!important;
  }
  .stand p {
   
    font-size: 0.78rem!important;
  }
}

@media only screen and (max-width: 922px) {
  .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    color: "#FFC000"!important;
    font-weight: bolder!important;
    font-size: 1.3rem !important;
  }
  .whyButton .btn {
    height: 2.5rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .stand {
    width: 55%!important;
  }
  .stand h4 {
    font-size: 0.8rem!important;
    font-weight: bold!important;
    margin-top: 0.20rem!important;
    margin-bottom: -1.20rem!important;
  }
  .stand p {
    
    font-size: 0.80rem!important;
  }
}

@media only screen and (max-width: 768px) {
  .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    color: "#FFC000"!important;
    font-weight: bolder!important;
    font-size: 1.2rem !important;
  }
  .whyButton .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
  }
  .stand {
    width: 50%!important;
  }
  .stand h4 {
    font-size: 0.8rem !important;
    font-weight: bold!important;
    margin-top: 0.25rem !important;
    margin-bottom: -1rem!important;
    
  }
  .stand p {
   
    font-size: 0.75rem!important;
  }
}

@media only screen and (max-width: 567px) {
  .why h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
   
    color: "#FFC000"!important;
    letter-spacing: 1px !important;
    font-weight: bolder!important;
    font-size: 1rem !important;
  }
  .whyButton .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
  }
  .stand {
    width: 65%!important;
  }
  .stand h4 {
    font-size: 0.70rem!important;
    
    font-weight: bold!important;
    margin-top: 0.30rem!important;
       margin-bottom: -0.90rem!important;
  }
  .stand p {
    font-size: 0.75rem!important;
  }
}
