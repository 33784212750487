@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}

.miningCover {
  background-image: 
    /* top, transparent red */ linear-gradient(
      to right,
      rgba(187, 185, 185, 0.925),
      rgba(187, 185, 185, 0.925),
      rgba(206, 201, 201, 0)
    ),
    /* your image */ url(../img/mining-equipment-hire.jpg) !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 900px !important;
  margin-top: -10 !important;
  opacity: 0.9 !important;
  filter: contrast(101%) !important;
  filter: brightness(130%) !important;
  object-fit: scale-down !important;

  background-color: rgba(12, 13, 14, 0.849) !important;
  overflow-x: hidden !important;
  transition: 0.5s !important;
}

.miningCover {
  display: flex !important;
  align-items: center !important;
}
.miningCover span {
  border-bottom: solid 4px #d6a614 !important;
}
.miningContent h1 {
  color: #000;
  margin-top: 2rem;

  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 900;
}

.miningContent {
  width: 30%;
  margin-left: 8%;
  color: black;
}

.miningContent p {
  font-size: 0.85rem;
  color: black;
}
.miningButton .btn1 {
  height: 2.7rem;

  border: 1px solid #d6a614 !important;
  border-radius: 4rem !important;
  background-color: #d6a614 !important;
  width: 12rem;
  font-size: 0.8rem;
  margin-top: 1.5rem;
}

@media only screen and (max-width: 1400px) {
  .miningContent h1 {
    font-size: 1.4rem !important;

    text-align: center !important;
    font-style: normal !important;

    font-weight: bold !important;
  }
  .miningCover span {
    border-bottom: solid 4px #d6a614 !important;
  }
  .miningContent {
    width: 36% !important;
    float: right !important;
    color: black !important;

    padding-top: 3rem !important;
    margin: 4% !important;
  }

  .miningContent p {
    font-size: 0.85rem !important;
  }

  .miningButton .btn1 {
    height: 2.7rem !important;
    width: 12rem !important;
    border-radius: 4rem !important;
    margin: 0.3rem !important;
    font-size: 0.83rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .miningContent h1 {
    font-size: 1.3rem !important;

    text-align: center !important;
    font-style: normal !important;

    font-weight: bold !important;
  }
  .miningCover span {
    border-bottom: solid 3.5px #d6a614 !important;
  }
  .miningContent {
    width: 45% !important;
    float: right !important;
    color: black !important;

    padding-top: 3rem !important;
    margin: 4% !important;
  }

  .miningContent p {
    font-size: 0.8rem !important;
  }

  .miningButton .btn1 {
    height: 2.4rem !important;
    width: 10rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
}

@media only screen and (max-width: 922px) {
  .miningContent h1 {
    font-size: 1.2rem !important;

    text-align: center !important;

    font-weight: bolder !important;
  }
  .miningCover span {
    border-bottom: solid 3.5px #d6a614 !important;
  }
  .miningContent {
    width: 50% !important;
    float: right !important;
    color: black !important;

    padding-top: 1rem !important;
    margin: 2% !important;
  }

  .miningContent p {
    font-size: 0.8rem !important;
  }

  .miningButton .btn1 {
    height: 2.3rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.7rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
}

@media only screen and (max-width: 768px) {
  .miningCover {
    display: flex !important;
    align-items: center !important;
    justify-items: center !important;
  }
  .miningContent h1 {
    font-size: 1.1rem !important;

    margin-top: 1rem !important;
    text-align: center !important;

    font-weight: bolder !important;
  }
  .miningContent {
    width: 57% !important;
    margin-left: 4% !important;
    color: black !important;

    text-align: left !important;
  }
  .miningButton .btn1 {
    height: 2.2 rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.72rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
  .miningCover span {
    border-bottom: solid 3.2px #d6a614 !important;
  }
  .miningContent p {
    font-size: 0.75rem !important;
  }
}

@media only screen and (max-width: 567px) {
  .miningCover {
    display: flex !important;
    align-items: center !important;
    justify-items: center !important;
    min-height: auto !important;
    background-position: 60% 40% !important;
  }
  .miningContent h1 {
    font-size: 1rem !important;

    margin-top: 1rem !important;
    text-align: center !important;

    font-weight: bolder !important;
  }
  .miningCover span {
    border-bottom: solid 3px #d6a614 !important;
  }
  .miningContent {
    width: 83% !important;
    padding-top: 15%!important;
    margin-bottom:40%!important;
    text-align: center!important;
  }
  .miningButton .btn1 {
    font-size: 0.63rem!important;
    font-weight: 400!important;
    height: 2.2rem!important;
    width: 8.5rem !important;
    margin: 0.5rem !important;
  }

  .miningContent p {
    font-size: 0.75rem !important;
  }
}
