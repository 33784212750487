@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap") ;
body {
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}

.what {
  margin-top: 2rem ;
  background-color: black !important;
  min-height: 100vh !important;
  color: #ffffff !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
}
.what{
  min-height:100vh !important;
}
 .what h1 {
    color: #ffc000 !important;
    margin-top: 1rem;

    font-weight: bolder !important;
    font-size: 1.3rem;
  }
.wedo {
  margin: auto !important;
  width: 35%;
}

 .wedo h4 {
    font-size: 0.9rem ;
    font-weight: bold ;
    margin-top: 0.3rem ;
    margin-bottom: -0.40rem;
   
  }


  .wedo p {
    margin: 0.5rem;
    font-size: 0.85rem;
  }
 .whatButton .btn {
    height: 2.7rem;
    width: 12rem;
    border-radius: 4rem;
    margin: 0.2rem;
    font-size: 0.80rem;
    text-align: center !important;
    background-color: #ffc000 !important;
  }
.btn {
  background: #ffc000 !important;
  text-decoration: none ;
}

.btn:hover {
  text-decoration: none !important;
  color: #000 !important;
}

@media only screen and (max-width: 1400px) {
  .what h1 {
    color: #ffc000 !important;
    margin-top: 1rem !important;

    font-weight: bolder !important;
    font-size: 1.3rem !important;
  }
  .whatButton .btn {
    height: 2.7rem !important;
    width: 12rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.80rem !important;
    text-align: center !important;
    background-color: #ffc000 !important;
  }
  .wedo {
    width:35% !important;
  }
  .wedo h4 {
    font-size: 0.83rem !important;
    font-weight: bold !important;
    margin-top: 0.35rem !important;
       margin-bottom: -0.40rem!important;
  }

  .wedo p {
    margin: 0.5rem !important;
    font-size: 0.85rem !important;
  }
}

@media only screen and (max-width: 1200px) {
  .what h1 {
    color: #ffc000 !important;
    margin-top: 1rem !important;

    font-weight: bolder !important;
    font-size: 1.2rem !important;
  }
  .whatButton .btn {
    height: 2.4rem !important;
    width: 10rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
    background-color: #ffc000 !important;
  }
  .wedo {
    width: 35% !important;
  }
  .wedo h4 {
    font-size: 0.75rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: bold !important;
    margin-top: 0.25rem !important;
       margin-bottom: -0.30rem!important;
  }

  .wedo p {
    margin: 0.5rem !important;
    font-size: 0.78rem !important;
  }
}

@media only screen and (max-width: 922px) {
  .what h1 {
    color: #ffc000 !important;
    margin-top: 1rem !important;
    font-weight: bolder !important;
    font-size: 1.3rem !important;
  }
  .whatButton .btn {
    height: 2.5rem !important;
    width: 10rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
    background-color: #ffc000 !important;
  }
  .wedo {
    width: 47% !important;
  }
  .wedo h4 {
    font-size: 0.78rem !important;
    font-weight: bold !important;
    margin-top: 0.3rem !important;
       margin-bottom: -0.50rem!important;
  }

  .wedo p {
    margin: 0.5rem !important;
    font-size: 0.78rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .what h1 {
    color: #ffc000 !important;
    margin-top: 1rem !important;
    font-weight: bolder !important;
    font-size: 1.3rem !important;
  }
  .whatButton .btn {
    height: 2.2rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
  }
  .wedo {
    width: 45% !important;
  }
  .wedo h4 {
    font-size: 0.75rem !important;
    font-weight: bold !important;
    margin-top: 0.3rem !important;
       margin-bottom: -0.40rem!important;
  }

  .wedo p {
 
    font-size: 0.75rem !important;
  }
}

@media only screen and (max-width: 567px) {
  .what h1 {
    color: #ffc000 !important;
    margin-top: 1.5rem !important;
    
    letter-spacing: 1px !important;
    font-weight: bolder!important;
    font-size: 1rem !important;
  }
  .whatButton .btn {
    height: 2.2rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.72rem !important;
    text-align: center !important;
  }
  .wedo {
    width: 65% !important;
  }
  .wedo h4 {
    font-size: 0.70rem!important;
    font-weight: bolder !important;
    margin: 0.3rem !important;
       margin-bottom: -0.40rem!important;
  }

  .wedo p {
    margin: 0.5rem !important;
    font-size: 0.75rem !important;
  }
}
