@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

html {
  scroll-behavior: smooth!important;
}

body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.about .col {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.about .bg-right {
  background-image: url("../img/PickTech\ Map1.jpg") !important;
 
  background-repeat: no-repeat!important;
  background-position: 75% 25%;
  background-size: cover!important;
  background: linear-gradient(rgba(0, 0, 0, 0.7))!important;
  overflow-x: hidden!important;
}
.about .row {
  min-height: 100vh!important;
}

.introCov {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.about .h4-seo {
    font-weight:bold!important;
    font-size: 1.3rem;
    letter-spacing: 0.5px;
  }

  .about .h5-seo {
    font-weight:600;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
  .about span {
    font-size: 0.85rem;
 text-align: left;
  }

  .intro {
    margin-left: auto;
    margin-right: auto;
    width: 55% ;
  }

/*XX-Large devices (larger desktops, 1400px and up)*/
@media only screen and (max-width: 1400px) {
  .about .h4-seo {
    font-weight: bold!important;
    font-size: 1.2rem!important;
    letter-spacing: 0.5px!important;
  }

  .about .h5-seo {
    font-weight: 600!important;
    font-size: 1rem!important;
    letter-spacing: 0.5px!important;
  }
  .about span {
    font-size: 0.85rem!important;

    text-align: left!important;
  }

  .intro {
    margin-left: auto!important;
    margin-right: auto!important;
    width: 55% !important;
  }
}

/* X-Large devices (large desktops, 1200px and up)*/
@media only screen and (max-width: 1200px) {
  .about .h4-seo {
    font-weight: bold !important;
    font-size:1rem !important;
    letter-spacing: 0.5px !important;
  }

  .about .h5-seo {
    font-weight: 600 !important;
    font-size: 0.85rem !important;
    letter-spacing: 0.5px !important;
  }
  .about span {
    font-size: 0.78rem !important;
    text-align: left !important;
  }
  .about .row {
    min-height: 100vh!important;
  }

  .intro {
    margin-left: auto!important;
    margin-right: auto!important;
    width: 55% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 992px) {
  .about .h4-seo {
    font-weight: bold!important;
    font-size: 0.9rem !important;
  }

  .about .h5-seo {
    font-weight: 600!important;
    font-size: 0.83rem !important;
  }
  .about span {
    font-size: 0.78rem !important;

    text-align: left!important;
  }

  .about .row {
    min-height: 115vh!important;
  }

  .intro {
    margin-left: auto!important;
    margin-right: auto!important;
    width: 55% !important;
  }
  .about .bg-right {
  background-position: 79% 21% !important;
}
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .about .h4-seo {
    font-weight: bold!important;
    font-size: 1rem !important;
  }

  .about .h5-seo {
    font-weight: 600!important;
    font-size: 0.9rem !important;
  }
  .about span {
    font-size: 0.75rem !important;

    text-align: left!important;
  }
  .about .row {
    min-height: 115vh !important;
  }
  .about .bg-right{
   background-position: 80% 20%!important;
  }

  .about .intro {
    margin-bottom: 2rem!important;
    margin-top: 2rem!important;
    margin-left: auto!important;
    margin-right: auto!important;
    width: 55% !important;
  }
}

@media only screen and (max-width: 576px) {
   .about .h4-seo {
    font-weight: bold!important;
    font-size: 0.85rem !important;
  }

  .about .h5-seo {
    font-weight: 600!important;
    font-size: 0.75rem !important;
  }
  .about span{
    font-size: 0.65rem!important;
text-align: left!important;
  }
  .about img {
    width: 100%!important;
  }
  .about .row {
    height: 170vh !important;
  }
  .intro {
    width: 70%!important;
  }
  .intro .h1-seo {
    font-size: 1rem !important;
   
    color: black!important;
    margin-top: 1rem!important;
    text-align: center!important;
  }
}
