@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}






.contacts header{
  

  min-width: 100%;
  background-color: black;
  height:15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contacts header h1{
 font-size:2.5rem;
  color:#FFFFFF;
  margin-top: 1rem;
text-align:center;

font-weight: bolder;
  
}

@media only screen and (max-width: 1400px) {
  .contacts header{
  min-width: 100%!important;
  background-color: black!important;
  height:22rem !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.contacts header h1{
 font-size:1.6rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  }

}
@media only screen and (max-width: 1200px) {
  .contacts header{
  

  min-width: 100%!important;
  background-color: black;
  height:20rem !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.contacts header h1{
 font-size:1.5rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  }


}



 @media only screen and (max-width: 922px) {

  
.contacts header{
  

  min-width: 100%!important;
  background-color: black;
  height:20rem !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.contacts header h1{
 font-size:1.2rem !important;
  color:#FFFFFF!important;
  margin-top: 1.1rem!important;
text-align:center!important;

font-weight: bolder!important;
  }}

 @media only screen and (max-width: 768px) {

  
.contacts header{
  

  min-width: 100%!important;
  background-color: black;
  height:20rem !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.contacts header h1{
 font-size:1.05rem !important;
  color:#FFFFFF !important;
  margin-top: 1rem !important;
text-align:center;

font-weight: bolder!important;
  }}

  @media only screen and (max-width: 567px) {

 
.contacts header{
  

  min-width: 100%!important;
  background-color: black;
  height:18rem !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.contacts header h1{
 font-size:0.9rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  }


  }