body { margin: 0 !important; }
.call-to-act{
  height:5rem!important;
  min-width: 100%!important;
  background-color: #FFC000;
  border-bottom-left-radius: 0.1rem;
border-bottom-right-radius: 0.1rem;
    display: flex!important;
    align-items: center !important;
    justify-content: center!important;
}
.call-to-act {
margin-bottom:3%!important;
}
.sign-button .btn {
  height:2.7rem;

border: 1px solid  #ffff!important;
border-style:double;
  border-radius: 4rem!important;
 margin: 1.3rem;
  width: 12rem!important;
  font-size: 0.83rem;

}
.btn {
 
  cursor: pointer;
  box-shadow: -1px 3px 3px 0 rgba(80, 80, 80, .2);
  
  flex: 0 0 auto;
}
.btn {
  
  transition: box-shadow 0.16s ease;
}

.btn:hover {
  
  box-shadow: none;
}




@media only screen and (max-width: 1400px){.sign-button .btn {
    height: 2.7rem!important;
    width: 12rem!important;
    border-radius: 4rem!important;
    margin: 1rem!important;
    font-size: 0.83rem !important;
    text-align: center!important;
   
  }}
@media only screen and (max-width: 1200px){  .sign-button .btn {
    height: 2.4rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 1rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
   
  }}
@media only screen and (max-width: 922px){.sign-button .btn {
    height: 2.3rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.8rem!important;
    font-size: 0.70rem !important;
    text-align: center!important;
   
  }}
@media only screen and (max-width: 768px){.sign-button .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.4rem!important;
    font-size: 0.72rem !important;
    text-align: center!important;
   
  }}
@media only screen and (max-width: 567px){ .sign-button .btn {
     font-size: 0.60rem !important;
    font-weight: 400!important;
    height: 2.2rem!important;
    width: 8.5rem!important;
    margin: 0.35rem!important;
   
  }}