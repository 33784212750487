@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

html {
  scroll-behavior: smooth!important;
}


body {
    
    background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -moz-font-smoothing: antialiased!important;
}

.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.team header h1{
 font-size:1.6rem!important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f!important;
    border-color: #108d6f!important;
    box-shadow: none!important;
    outline: none!important;
}

.btn-primary {
    color: #fff!important;
    background-color: #d6a614!important;
    border-color: #d6a614!important;
}

section {
    padding: 60px 0!important;
}



#team .card {
    border: none!important;
    background: #ffffff!important;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg)!important;
    -moz-transform: rotateY(0deg)!important;
    -o-transform: rotateY(0deg)!important;
    -ms-transform: rotateY(0deg)!important;
    transform: rotateY(0deg)!important;
    border-radius: .25rem!important;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg)!important;
    -moz-transform: rotateY(180deg)!important;
    -o-transform: rotateY(180deg)!important;
    transform: rotateY(180deg)!important;
}

.mainflip {
    -webkit-transition: 1s!important;
    -webkit-transform-style: preserve-3d!important;
    -ms-transition: 1s!important;
    -moz-transition: 1s!important;
    -moz-transform:perspective(1000px)!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
    transition: 1s!important;
    transform-style: preserve-3d!important;
    position: relative!important;
}

.frontside {
    position: relative!important;
    -webkit-transform: rotateY(0deg)!important;
    -ms-transform: rotateY(0deg)!important;
    z-index: 2!important;
    margin-bottom: 30px!important;
}

.backside {
    position: absolute!important;
    top: 0!important;
    left: 0!important;
    background: white!important;
    -webkit-transform: rotateY(-180deg)!important;
    -moz-transform: rotateY(-180deg)!important;
    -o-transform: rotateY(-180deg)!important;
    -ms-transform: rotateY(-180deg)!important;
    transform: rotateY(-180deg)!important;
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158)!important;
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158)!important;
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158)!important;
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden!important;
    -moz-backface-visibility: hidden!important;
    -ms-backface-visibility: hidden!important;
    backface-visibility: hidden!important;
    -webkit-transition: 1s!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transition: 1s!important;
    -moz-transform-style: preserve-3d!important;
    -o-transition: 1s!important;
    -o-transform-style: preserve-3d!important;
    -ms-transition: 1s!important;
    -ms-transform-style: preserve-3d!important;
    transition: 1s!important;
    transform-style: preserve-3d!important;
}

.frontside .card,
.backside .card {
    min-height: 312px!important;
}



.frontside .card .card-title,
.backside .card .card-title {
    color: #d6a614 !important;
  
}

.frontside .card .card-body img {
    width: 120px!important;
    height: 120px!important;
    border-radius: 50%!important;
}

.role-name {
    font-size: 1rem;
}


.team .fa{
color:#d6a614!important;
}

@media only screen and (max-width: 1400px) {
.role-name {
    font-size: 0.80rem!important;
}

.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.team header h1{
 font-size:1.3rem !important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.team  .team-team{
    font-weight: 500 !important;
    font-size: 0.90rem !important;
width:65% ;
margin-left:auto !important;
margin-right: auto !important;


}
.card-title{
    font-size: 0.75rem!important;
}
.backside .card-text{
    font-size: 0.80rem !important;
    font-weight: 300;
}}
    @media only screen and (max-width: 1200px) {
section {
    padding: 15px 0!important;
}
.role-name {
    font-size: 0.75rem!important;
}

.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.team header h1{
 font-size:1.3rem !important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.team .team-team{
    font-weight: 400 !important;
    font-size: 0.80rem !important;
width:65%;
margin-left:auto !important;
margin-right: auto !important;

}
 .card-title {
    font-size:0.75rem!important;
}
.backside .card-text{
    font-size: 0.75rem !important;
    font-weight: 200;
}}
@media only screen and (max-width: 922px) {
.team header h1{
 font-size:1.2rem !important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:20rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
section {
    padding: 5px 0!important;
}
.role-name {
    font-size: 0.75rem!important;
}

.team .team-team{
    font-weight: 300 !important;
    font-size: 0.78rem !important;
width:85%;
margin-left:auto !important;
margin-right: auto !important;
margin-top: 2rem !important;
margin-bottom: 1.2rem !important;
}
.backside .card-text{
    font-size: 0.7rem !important;
    font-weight: 200;
}
.card-title{
    font-size: 0.75rem!important;
}
}
@media only screen and (max-width: 768px) {

.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:18rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.team header h1{
 font-size:1rem !important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;
letter-spacing: 2px!important;
text-transform: uppercase!important;
font-weight: bolder!important;
  
}
section {
    padding: 5px 0!important;
}
.role-name {
    font-size: 0.70rem!important;
}

.team .team-team{
    font-weight: 400 !important;
    font-size: 0.75rem !important;
width:75%;
margin-left:auto !important;
margin-right: auto !important;
margin-top: 2rem !important;
margin-bottom: 1.2rem !important;
}
.backside .card-text{
    font-size: 0.70rem !important;
    font-weight: 300;
}
.backside .card-title{
font-size: 0.75rem !important;
}
}
@media only screen and (max-width: 567px) {
.role-name {
    font-size: 0.70rem!important;
}

.frontside .card,
.backside .card {
    min-height:212px!important;
    margin-left:1.8rem!important;
    margin-right:1.8rem!important;
}

.team header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:17rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.team header h1{
 font-size:0.8rem !important;
  color:white!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.team .team-team{
    font-weight: 500 !important;
    font-size: 0.73rem !important;
width:70%;
margin-left:auto !important;
margin-right: auto !important;
margin-top: 2.5rem !important;
margin-bottom: 0.3rem;
}
.team .card-title{
    font-size: 0.75rem !important;
}
.backside .card-text{
    font-size: 0.70rem !important;
    font-weight: 300;
}
}