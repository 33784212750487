@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.farmingCover {
  background-image: 
    /* top, transparent red */ linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(245, 245, 245, 0.014),
      rgba(3, 3, 3, 0.596),
      rgba(3, 3, 3, 0.925),
      rgb(0, 0, 0)
    ),
    /* your image */ url(../img/farming-equipment-hire.jpg)!important;
  background-position: center center;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  min-height: 900px!important;
  margin-top: -10!important;
  opacity: 0.9!important;
  filter: contrast(101%)!important;
  filter: brightness(130%)!important;
  object-fit: scale-down!important;

  background-color: rgba(12, 13, 14, 0.849)!important;
  overflow-x: hidden!important;
  transition: 0.5s!important;
}
.farmingContent h1 {
font-size: 1.3rem ;
    color: #ffffff !important;
 margin-top: 2rem;
    text-align: center ;
    
text-transform: uppercase!important;
    font-weight: bolder !important;
 
}
.farmingContent {
  width: 27%;
  float: right;
  color: #ffffff;

  margin: 7%!important;
}

.farmingButton {
  margin-top: 1rem;
}
  .farmingCover span{
  border-bottom: solid 4px #d6a614!important;
  }
   .farmingContent p {
    font-size:0.85rem;
  }
.farmingButton .btn {
  height: 2.7rem;
 border-radius: 4rem;
  background-color: #d6a614;
  width: 12rem;
  font-size: 00.83rem;
  margin: 0.2rem;
}
@media only screen and (max-width: 1400px) {
  .farmingContent h1 {
    font-size: 1.4rem !important;
    color: #ffffff !important;

    text-align: center !important;
    
text-transform: uppercase!important;
    font-weight: bolder !important;
  }

  .farmingContent {
    width: 37%!important;
    float: right!important;
    color: #ffffff!important;
   
    padding-top: 15% !important;
    margin: 4%!important;
  }
  .farmingCover span{
  border-bottom: solid 4px #d6a614!important;
}

  .farmingContent p {
    font-size: 0.85rem!important;
  }
.farmingButton{
  padding-top: 1rem!important;
}
  .farmingButton .btn {
    height: 2.7rem!important;
    width: 12rem!important;
    border-radius: 4rem!important;
    margin: 0.3rem!important;
    font-size: 0.83rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }
}
@media only screen and (max-width: 1200px) {
  .farmingContent h1 {
    font-size: 1.3rem !important;
    color: #ffffff !important;

    text-align: center !important;
   
   
    font-weight: bolder !important;
  }
.farmingCover span{
  border-bottom: solid 3.5px #d6a614!important;
}
  .farmingContent {
    width: 45%!important;
    float: right!important;
    color: #ffffff!important;
   
    padding-top: 15% !important;
    margin: 4%!important;
  }

  .farmingContent p {
    font-size: 0.80rem!important;
    
  }
.farmingButton {
  padding-top: 1rem !important;
}
  .farmingButton .btn {
    height: 2.4rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 0.1rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
    
  }
  .farmingButton{
padding-top: 0.7rem!important;
  }
}
@media only screen and (max-width: 992px) {
  .farmingContent h1 {
    font-size: 1.2rem !important;
    color: #ffffff !important;
letter-spacing: 2px!important;
    text-align: center !important;
    font-style: normal !important;
   
    font-weight: bolder !important;
  }
.farmingCover span{
  border-bottom: solid 3.5px #d6a614!important;
}
  .farmingContent {
    width: 50%!important;
    float: right!important;
    color: #ffffff!important;
   
    padding-top:30%!important;
    margin: 4%!important;
  }

  .farmingContent p {
    font-size: 0.80rem!important;
    
  }

  .farmingButton  .btn {
    height: 2.3rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.70rem !important;
    
    text-align: center!important;
    background-color: #d6a614!important;
  }
}
@media only screen and (max-width: 768px) {
  .farmingContent h1 {
    font-size: 1.1rem !important;
    color: #ffffff !important;

    text-align: center !important;
    font-weight: bolder!important;
    text-transform: uppercase!important;
   
  }
.farmingCover span{
  border-bottom: solid 3px #d6a614!important;
}
  .farmingContent {
    width: 59%!important;
    float: right!important;
    color: #ffffff!important;
    padding-top:35% !important;
  }

  .farmingContent p {
    font-size: 0.75rem!important;
  }

  .farmingButton .btn {
    height: 2.2rem!important;
    width: 8rem!important;
    border-radius: 5rem !important;
    margin: 0.2rem!important;
    font-size: 0.70rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }
}

@media only screen and (max-width: 567px) {
  
  .farmingContent h1 {
    color: #ffffff !important;
    margin-top: 1rem!important;

    font-size: 1rem!important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    text-align: center!important;
    font-weight: bolder!important;
  }
  .farmingCover {
    display: flex!important;
    align-items: center!important;
    justify-items: center!important;
    background-position: 60% 40%!important;
    min-height: auto!important;
  }
  .farmingContent {
    width: 90%!important;
    padding-top: 15%!important;
margin-bottom:50%!important;
    color: #ffffff!important;
  }

  .farmingContent p {
    font-size: 0.75rem!important;
  }
.farmingCover span{
  border-bottom: solid 2.8px #d6a614!important;
}
  .farmingButton .btn {
     font-size: 0.62rem !important;
    font-weight: 400!important;
    height: 2.2rem!important;
    width: 8.5rem!important;

    margin: 0.5rem!important;
    background-color: #d6a614!important;
    
  }
}
