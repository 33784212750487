@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body{
    background: #f2f2f2;
    font-family: 'Poppins', sans-serif;
}


.sec1{
    min-height: auto
    
     
}

.sec1 .row{
min-height:100vh;
}

.introCov1{
  display:flex;
  justify-content: center;
  align-items: center;

 
}

.sec1 span{
  font-size:0.85rem !important;
  text-align: left;}

.intro1{
  width:55%;
 letter-spacing: 0.5px;
}

.sec1 .row {
  
    padding:0;
    margin:0;
   
}
 

.bg-left {
   background-image: url("../img/cargo2.jpg") !important;
     background-position:70% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(rgba(0,0,0,.7));
   overflow-x: hidden;
  padding:0;
    margin:0;
   
}


@media only screen and (max-width: 1400px) {



.sec1 span{
        font-size:0.85rem !important;
   text-align: left;
  
    
} }
@media only screen and (max-width: 1200px) {



.sec1 span{
        font-size: 0.78rem !important;
  
    text-align: center;
    
} 
}
@media only screen and (max-width: 992px) {
.sec1 span{
     font-size: 0.75rem !important;
    
     text-align: left;
} 
.bg-left {
     background-position:60% 40% !important;
}


}
@media only screen and (max-width: 768px) {
.sec1 span{
     font-size: 0.75rem !important;
   text-align: center !important;
} 
}
@media only screen and (max-width: 567px) {

.sec1 .phone-row{
  height:140vh !important;
}
.sec1 span{
     font-size:0.65rem!important;
}

}






