@keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }
  to {
    top: 100;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }
  to {
    top: 100;
    opacity: 1;
  }
}

.wrapper {
  height: 70vh;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  
  font-weight: 700;
}

.dot {
  color: #4febfe;
}

p {
  text-align: center;
  margin: 18px;
  font-family: "Muli", sans-serif;
  font-weight: normal;
}

.icons i:hover,
.icons i:active {
  color: gray;
  background: none;
  cursor: pointer !important;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  text-decoration: none;
}

.icons i {
  margin: 0.5rem;
  font-size: 2rem;
  color: #ffc000;
}
