.support-cover p {
border-bottom: 1px solid grey;
text-align: left;
}

.support-cover{
    min-height:100vh!important;
      background-color: white!important;
      

}


.support-content{
    margin: 0!important;
}

.breadcrumb-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 104px 0 110px;
    position: relative;
    width:100%;
}
.breadcrumb-image::before {
    background: rgba(46, 38, 1, 0.8) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.breadcrumb-image h1 {
    color: #ffff;
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
}
.breadcrumbs_path {
      color: #ffff;
    margin-top: 8px;
    position: relative;
    z-index: 9;
}
.breadcrumbs_path > a {
      color: #ffff;
    transition: all 0.3s ease 0s;
}
.center{
   max-width: 70%;
   margin: auto;
   margin-top: 3rem;
}



@media only screen and (max-width: 1400px) { .support-cover p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .support-cover p{
        font-size: 0.80rem!important;
    }
  
}
@media only screen and (max-width: 922px) { .support-cover p{
        font-size: 0.80rem!important;

    }
    .support-cover h3{
     text-align: center;
      font-size: 1.5rem!important;
      
 }  
 .breadcrumb-image h1 {
    color: #ffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
}
 
}
@media only screen and (max-width: 768px) {
     .support-cover p{
        font-size: 0.75rem!important;
    }

    

}

@media only screen and (max-width: 567px) {
    .support-cover p{
        font-size: 0.75rem!important;
    }
    
    
}