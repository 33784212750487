@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.who img {
  height: 20rem;
  width: 20rem;
}

.who {
  min-height: 110vh!important;
  display: flex!important;
  align-items: center!important;
  margin-top: 8rem;
}

.whoare {
  margin: auto!important;
  width: 80%;
}

.who h1 {
    color: #ffc000 !important;
    margin-top: 1rem;
text-align: center;
    font-weight: bolder!important;
    font-size: 1.3rem;
  }
  .who p {
    margin: 0.5rem;
    font-size: 0.85rem;
  }

  .whoButton .btn {
    height:2.7rem;
    width: 12rem;
    border-radius: 4rem;
    margin: 0.2rem;
    font-size: 0.85rem;
    text-align: center!important;
    background-color: #ffc000!important;
  }



@media only screen and (max-width: 1400px) {
  .who h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;

    font-weight: bolder!important;
    font-size: 1.3rem !important;
  }
  .who p {
    margin: 0.5rem!important;
    font-size: 0.85rem!important;
  }

  .whoButton .btn {
    height: 2.7rem!important;
    width: 12rem!important;
    border-radius: 4rem!important;
    margin-top: 0.7rem!important;
    font-size: 0.80rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .who img {
  height: 18.5rem!important;
  width: 18.5rem!important;
}
.whoIntro{width:100%;}
.whoare{
  width: 75%;
}
}

@media only screen and (max-width: 1200px) {
  .who h1 {
    color: #ffc000 !important;
    margin-bottom: 0.7rem!important;
    font-weight: bolder!important;
    font-size: 1.2rem !important;
  }
  .who p {
    margin: 0.25rem!important;
    font-size: 0.78rem!important;
  }

  .whoButton .btn {
    height: 2.4rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin-top: 0.7rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .who img {
  height: 17.5rem!important;
  width: 17.5rem!important;
}
.whoIntro{width:100% !important;}
.whoare{
  width: 70%;
}
}

@media only screen and (max-width: 922px) {
  .who h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    color: "#FFC000"!important;

    font-weight: bolder!important;
    font-size: 1.3rem !important;
  }
  .whoIntro {
    width: 55%!important;
    margin: auto!important;
  }
  .whoPic {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }
  .who p {
    margin: 0.25rem!important;
    font-size: 0.80rem!important;
  }

  .whoButton .btn {
    height: 2.5rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #ffc000!important;
  }
  .who img {
  height: 15.5rem!important;
  width: 15.5rem!important;
}
}

@media only screen and (max-width: 768px) {
  .who h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    font-weight: bolder!important;
    font-size: 1.3rem !important;
  }
  .who p {
    margin: 0.25rem!important;
    font-size: 0.75rem!important;
  }
  .whoIntro {
    width: 45%!important;
    margin: auto!important;
  }
  .whoPic {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }

  .whoButton .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
  }
  .who img {
  height: 14.5rem!important;
  width: 14.5rem!important;
}
}

@media only screen and (max-width: 567px) {
  .who h1 {
    color: #ffc000 !important;
    margin-top: 1rem!important;
    color: "#FFC000"!important;
    
    font-weight: bolder!important;
    font-size: 1.1rem !important;
  }
  .who p {
    
    margin-top: 0.3rem!important;
    font-size: 0.75rem!important;
  }
  .who{
    width: 100%!important;
  }

  .whoButton .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.69rem !important;
    text-align: center!important;
    margin-top: 1rem!important;
  }
  .who img {
  height: 13.5rem!important;
  width: 13.5rem!important;
}
.whoIntro{
  width:68%!important;
}
}
