@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}

.tap {
  background-color: #ffc000;
}

.ico-wrap {
  margin: auto !important;
}

.vcenter {
  margin: auto !important;
}

.benefit-text .mbr-section-title3 {
  text-align: center !important;
}

.benefit-text .mbr-bold {
  font-weight: 700 !important;
}

.benefit-text p {
  padding-top: 0.2em !important;
  padding-bottom: 0.2rem !important;
}
.benefit-text .display-6 {
  text-align: center !important;
  font-size: 1rem;
}

.benefit-cover img {
  height: 3rem !important;
  width: 3rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.benefit-cover .ico-wrap {
  margin-bottom: -1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.benefit-cover .display-5 {
  font-size: 1.1rem;
}

.benefit-cover .display-6 {
  font-size: 1rem;
  text-align: center !important;
}

@media only screen and (max-width: 1400px) {
  .benefit-cover img {
    height: 3rem !important;
    width: 3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .benefit-cover .ico-wrap {
    margin-bottom: -1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .benefit-cover .display-5 {
    font-size: 1rem;
  }

  .benefit-cover .display-6 {
    font-size: 0.9rem;
    text-align: center !important;
  }

  .benefit-cover header {
    max-width: 100% !important;
    background-color: black !important;
    height: 16rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .benefit-cover header h1 {
    font-size: 1.5rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    letter-spacing: 2px !important;
    font-weight: bolder !important;
  }
  .tooltip-inner {
    opacity: 0.9 !important;
    background-color: #ffc000;
    color: black;
    font-weight: 300;
    font-size: 0.75rem !important;
    border: solid white 1px;
    border-top-left-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }
}

@media only screen and (max-width: 1200px) {
  .benefit-cover img {
    height: 2.5rem !important;
    width: 2.5rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .benefit-cover .ico-wrap {
    margin-bottom: -1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .benefit-cover .display-5 {
    font-size: 0.9rem !important;
  }

  .display-6 {
    font-size: 0.8rem !important;
  }

  .benefit-cover header {
    max-width: 100% !important;
    background-color: black !important;
    height: 16rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  header h1 {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    letter-spacing: 2px !important;
    font-weight: bolder !important;
  }
  .tooltip-inner {
    opacity: 0.9 !important;
    background-color: #ffc000;
    color: black;
    font-weight: 300;
    font-size: 0.75rem !important;
    border: solid white 1px;
    border-top-left-radius: 2.3rem !important;
    border-bottom-right-radius: 2.3rem !important;
  }
}
@media only screen and (max-width: 922px) {
  .benefit-cover img {
    height: 2.3rem !important;
    width: 2.3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ico-wrap {
    margin-bottom: -2rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .display-5 {
    font-size: 1rem !important;
  }

  .display-6 {
    font-size: 0.8rem !important;
  }

  .benefit-cover header {
    max-width: 100% !important;
    background-color: black !important;
    height: 16rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  header h1 {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    letter-spacing: 2px !important;
    font-weight: bolder !important;
  }

  .tooltip-inner {
    opacity: 0.9 !important;
    background-color: #ffc000;
    color: black;
    font-weight: 300;
    font-size: 0.8rem !important;
    border: solid white 1px;
    border-top-left-radius: 2.3rem !important;
    border-bottom-right-radius: 2.3rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .display-5 {
    font-size: 0.5rem !important;
  }

  .display-6 {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .benefit-cover header {
    max-width: 100% !important;
    background-color: black !important;
    height: 16rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  header h1 {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    letter-spacing: 2px !important;
    font-weight: bolder !important;
  }
  .benefit-cover img {
    height: 2.1rem !important;
    width: 2.1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ico-wrap {
    margin-bottom: -1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .tooltip-inner {
    opacity: 0.9 !important;
    background-color: #ffc000;
    color: black;
    font-weight: 300;
    font-size: 0.75rem !important;
    border: solid white 1px;
    border-top-left-radius: 2.3rem !important;
    border-bottom-right-radius: 2.3rem !important;
  }
}
@media only screen and (max-width: 567px) {
  

  .display-5 {
    font-size: 0.75rem !important;
  }

  .display-6 {
    font-size: 0.8rem !important;
  }

  .benefit-cover header {
    max-width: 100% !important;
    background-color: black !important;
    height: 16rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .benefit-cover h1 {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    letter-spacing: 2px !important;
    font-weight: bolder !important;
  }
  .benefit-cover img {
    height: 3rem !important;
    width: 3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ico-wrap {
    margin-bottom: -2rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .tooltip-inner {
    opacity: 0.9 !important;
    background-color: #ffc000;
    color: black;
    font-weight: 300;
    font-size: 0.75rem !important;
    border: solid white 1px;
    border-top-left-radius: 2.3rem !important;
    border-bottom-right-radius: 2.3rem !important;
  }
  .benefit-cover Button {
    font-size: 0.75rem !important;
  }
}
