.pay-cover p {
border-bottom: 1px solid grey;
text-align: left;
}

.pay-cover{
    min-height:100vh!important;
    background-color: white!important;
 
}
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  
  font-family: "Poppins", sans-serif!important;
}
.pay-cover h6{
text-transform: uppercase;
font-weight:bold!important;
}
.pay-cover p{
text-align: left;
}

@media only screen and (max-width: 1400px) { .pay-cover p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .pay-cover p{
        font-size: 0.80rem!important;
    }
}
@media only screen and (max-width: 922px) { .pay-cover p{
        font-size: 0.80rem!important;

    }
    .pay-cover h3{
     text-align: center;
      font-size: 1.5rem!important;
      
 }  
}
@media only screen and (max-width: 768px) {
     .pay-cover p{
        font-size: 0.75rem!important;
    }
}

@media only screen and (max-width: 567px) {
    .pay-cover p{
        font-size: 0.75rem!important;
    }
    
}