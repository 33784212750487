@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

html {
  scroll-behavior: smooth;
}

body{
    background:white;
    font-family: 'Poppins', sans-serif;
}
.facilitator-cover ul{
list-style:none;
}
.facilitator-cover strong{
    font-weight: bolder!important;
    text-transform: uppercase;
}
.facilitator-cover span{
display:block;
margin-top:0.3rem;
font-weight: 400;
}
.facilitator-cover{
    margin: 1rem !important;
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facilitator-content {
    width:80% ;
}
.facilitator-content h4 {
    margin-top: 0.5rem ;
}

@media only screen and (max-width: 1400px) {
    .facilitator-content span{
    font-size: 0.90rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
.facilitator-content li{
    font-size: 0.90rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
.facilitator-content {
    width:75% !important;
}
.facilitator-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
}
.facilitator-content h3 {
font-size: 1.2rem !important;
margin: 1.5rem !important;
border:3px solid black!important;
border-radius:1rem!important;
padding:0.8rem !important;
text-align: center;
background-color: #FFC000 !important;
}
.facilitator-cover li{
    margin-top: 0.5rem! important;
}
}
@media only screen and (max-width: 1200px) {
    .facilitator-content span{
    font-size: 0.80rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
    .facilitator-content li{
    font-size: 0.80rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
.facilitator-content {
    width:80% !important;
}
.facilitator-content h4 {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
}
.facilitator-content h3 {
font-size: 1.2rem !important;

margin: 1.5rem !important;
border:3px solid black!important;
border-radius:1rem!important;
padding:0.8rem !important;
text-align: center;
background-color: #FFC000;
}
}
@media only screen and (max-width: 922px) {
    .facilitator-content span{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.2rem !important;
}
  .facilitator-content li{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.2rem !important;
}
.facilitator-content {
    width:95% !important;
}
.facilitator-content h4 {
    margin-top: 0.5rem !important;
    font-size: 0.9rem !important;
    font-weight: 700 !important;
}
.facilitator-content h3 {
font-size: 1.2rem !important;
margin: 1.5rem !important;
border:3px solid black!important;
border-radius:1rem!important;
padding:0.8rem !important;
text-align: center;
background-color: #FFC000;
}
}
@media only screen and (max-width: 768px) {
    .facilitator-content span{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
   .facilitator-content li{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.3rem !important;
}
.facilitator-content {
    width:90% !important;
    
}
.facilitator-content h4 {
    margin-top: 0.5rem !important;
    font-size: 0.9rem !important;
    font-weight: 700 !important;
}
.facilitator-content h3 {
font-size: 1.2rem !important;

margin: 1.5rem !important;
border:3px solid black!important;
border-radius:1rem!important;
padding:0.8rem !important;
text-align: center;
background-color: #FFC000;

}
}
@media only screen and (max-width: 567px) {

.facilitator-content span{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.5rem !important;
}
.facilitator-content li{
    font-size: 0.75rem !important;
    font-weight: 300!important;
    line-height: 1.5rem !important;
}
.facilitator-content {
    width:95% !important;
}
.facilitator-content h4 {
    margin-top: 0.5rem !important;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
}
.facilitator-content h3 {
font-size: 1rem !important;

margin: 1.5rem !important;
border:3px solid black!important;
border-radius:1rem!important;
padding:0.8rem !important;
text-align: center;
background-color: #FFC000;

}
}