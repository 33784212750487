@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body{
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}

.freightContent h1 {
  color: #ffffff ;
  margin-top: 2rem;
 font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 1rem;
}
.freightContent {
  width: 30% ;
  margin-top:15%;
  margin-left:10%;
}

.freightContent p {
  font-size: 0.85rem ;
  
  color: #ffffff ;
  text-align: center ;
}

.freightCover {
  background-image: 
    /* top, transparent red */ linear-gradient(
      rgba(0, 0, 0, 0.555),
      rgba(1, 1, 3, 0.945)
    ),
    /* your image */ url(../img/cargo1.webp) !important;
  background-position: 35% 65%;
  background-repeat: no-repeat !important;
  -webkit-background-size: 100% !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  height: 100vh !important;
  width: 100vw !important;
  margin-top: -10 !important;
  opacity: 0.9 !important;
  filter: contrast(101%) !important;
  filter: brightness(130%) !important;
  object-fit: scale-down !important;

  background-color: rgba(12, 13, 14, 0.849) !important;
  overflow-x: hidden !important;
  transition: 0.5s !important;
}
.freightButton .btn {
  height: 2.7rem ;
  border: 1px solid #d6a614 ;
  border-radius: 4rem ;
  background-color: #d6a614;
  font-size:0.83rem;
  width: 12rem ;
  margin-top: 1rem ;
}
    .head span{
  border-bottom: solid 4.5px #d6a614;
}
@media only screen and (max-width: 1400px) {
  .freightContent h1 {
    font-size: 1.3rem !important;
    color: #ffffff !important;

    text-align: center !important;
    font-style: normal !important;
   
    font-weight: bold !important;
  }

  .freightContent {
    width: 36% !important;
  color: #ffffff !important;
    font-weight: 500 !important;
    padding-top: 15%!important;
    margin: 4% !important;
  }

  .freightContent p {
    font-size: 0.85rem !important;
  
  }
  .freightCover{
    background-position:35% 65%!important;
  }

  .freightButton .btn {
    height: 2.7rem !important;
    width: 12rem !important;
    border-radius: 4rem !important;
    margin: 0.3rem !important;
    font-size: 0.83rem !important;
    text-align: center !important;
    background-color: #d6a614!important;
  }
      .head span{
  border-bottom: solid 4.5px #d6a614!important;
}
}

@media only screen and (max-width: 1200px) {
  .freightContent h1 {
    font-size: 1.3rem !important;
    color: #ffffff !important;
 text-align: center !important;
    font-weight: bolder !important;
  }

  .freightContent {
    width: 40% !important;
   
    color: #ffffff !important;
    font-weight: 500 !important;
    padding-top: 15%!important;
    margin-top:10%!important;
    margin-left:10%!important;
  }

  .freightContent p {
    font-size: 0.80rem !important;
  }

  .freightButton .btn {
    height: 2.4rem !important;
    width: 10rem !important;
    border-radius: 4rem !important;
    margin: 0.3rem !important;
    font-size: 0.75rem !important;
    font-weight: 300;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
      .head span{
  border-bottom: solid 4px #d6a614!important;
}
 .freightCover{
    background-position:35% 65%!important;
  }
}

@media only screen and (max-width: 922px) {
  .freightContent h1 {
    font-size: 1.2rem !important;
    color: #ffffff !important;

    text-align: center !important;
    font-style: normal !important;
   
    font-weight: bold !important;
  }
 .freightCover{
    background-position:45% 55%!important;
  }
  .freightContent {
    width: 48% !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    padding-top: 20% !important;
    margin: 4% !important;
  }

  .freightContent p {
    font-size: 0.80rem !important;
  }

  .freightButton .btn {
    height: 2.3rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-size: 0.70rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
      .head span{
  border-bottom: solid 3.5px #d6a614!important;
}

}

@media only screen and (max-width: 768px) {
   .freightCover {
   
    background-position: 20% 80% !important;
  }

  .freightContent h1 {
    font-size: 1.1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    font-style: normal !important;

    font-weight: bold !important;
  }
  .freightCover {
    display: flex !important;
    align-items: center !important;
    justify-items: center !important;
    padding: 5% !important;
  }
  .freightContent {
    width: 70% !important;
    float: right !important;
    color: #ffffff !important;
    text-align: center !important;
    font-weight: 500 !important;
    padding-top: 3rem !important;
    margin-top: 3rem !important;
  }

  .freightContent p {
    font-size: 0.85rem !important;

    color: #ffffff !important;
  }
  .freightButton .btn {
    height: 2.2rem !important;
    width: 9rem !important;
    border-radius: 4rem !important;
    margin: 0.1rem !important;
    font-size: 0.70rem !important;
    text-align: center !important;
    background-color: #d6a614 !important;
  }
      .head span{
  border-bottom: solid 3.2px #d6a614!important;
}
}

@media only screen and (max-width: 567px) {
  .freightCover {
   
    background-position: 55% 45% !important;
  }

  .freightContent h1 {
    font-size: 1rem !important;
    color: #ffffff !important;
    margin-top: 1rem !important;
    text-align: center !important;
    font-style: normal !important;
    
    font-weight: bold !important;
  }
  .freightCover {
    display: flex !important;
    align-items: center !important;
    justify-items: center !important;
    padding: 5% !important;
  }
  .freightContent {
    width: 100% !important;
    float: right !important;
    color: #ffffff !important;
    text-align: center !important;
    font-weight: 500 !important;
    padding-top: 3rem !important;
    margin-top: 3rem !important;
    margin-bottom:40%!important;
  }

  .freightContent p {
    font-size: 0.75rem !important;
    
    color: #ffffff !important;
  }
  .freightButton .btn {
    font-size: 0.62rem !important;
    font-weight: 400!important;
    height: 2.2rem !important;
    width: 8.5rem !important;

    margin: 0.5rem !important;
  }

    .head span{
  border-bottom: solid 3px #d6a614!important;
}
}
