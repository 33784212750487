@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");


body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.title {
  color: #fa9746!important;
  margin-bottom: 30px!important;
  position: relative!important;
}

.title::before {
  position: absolute!important;
  left: -29px!important;
  top: 0!important;
  height: 20px!important;
  width: 20px!important;
  transform: rotate(45deg)!important;
  background-color: #ffc000!important;
  box-shadow: 0px 4px 4px 0px #aaa!important;
  border-radius: 4px!important;
  content: ""!important;
}

.title::after {
  position: absolute!important;
  left: -19px!important;
  top: 25px!important;
  height: 14px!important;
  width: 14px!important;
  transform: rotate(45deg)!important;
  background-color: #ffc000!important;
  box-shadow: 0px 4px 4px 0px #aaa!important;
  border-radius: 4px!important;
  content: ""!important;
}

.services-section {
  padding: 40px!important;
  position: relative!important;
  min-width: 100%!important;
}

.services-section .square {
  height: 100px!important;
  width: 100px!important;
  margin: 30px auto 40px!important;
  position: relative!important;
  border-radius: 15px!important;
  transform: rotate(45deg)!important;
  padding: 10px!important;
  background-color: #ffc000!important;
  box-shadow: 0px 0px 27px 0px #ccc!important;
  border: 2px dotted #eaf3fa!important;
}

.square i {
  transform: rotate(315deg)!important;
  display: block!important;
  color: #eaf3fa!important;
  font-size: 40px!important;
  padding-top: 17px!important;
  padding-right: 14px!important;
}

.services-section .square::before {
  position: absolute!important;
  left: -10px!important;
  top: 0!important;
  width: 1px!important;
  height: 100%!important;
  background-color: #fa9746!important;
  content: ""!important;
}

.services-section .square::after {
  position: absolute!important;
  left: 50px!important;
  top: 66px!important;
  width: 1px!important;
  height: 88%!important;
  background-color: #fa9746!important;
  content: ""!important;
  transform: rotate(90deg)!important;
}

.services-section .serv .num {
  width: 60px!important;
  height: 60px!important;
  background-color: #ffc000!important;
  box-shadow: 0px 0px 15px 0px #ffc000!important;
  margin: -38px auto 15px auto!important;
  border-radius: 50%!important;
  color: #fff!important;
  font-size: 30px!important;
  padding-top: 7px!important;
  border: 1px dashed!important;
}

.services-section .serv h5 {
  color: black!important;
}

.services-section .serv p {
  color: black!important;
}

.services-section .serv hr {
  width: 30px!important;
  border-top: 3px solid rgb(0 169 211)!important;
}
.title {
  color: black!important;
  margin-bottom: 4rem!important;
  position: relative!important;
  padding-bottom: 3rem!important;
}
.text-c {
  position: absolute!important;
  left: 5rem!important;
}

.title::before {
  position: absolute!important;
  left: -29px!important;
  top: 0!important;
  height: 20px!important;
  width: 20px!important;
  transform: rotate(45deg)!important;
  background-color: #ffc000!important;
  box-shadow: 0px 4px 4px 0px #aaa!important;
  border-radius: 4px!important;
  content: ""!important;
}

.title::after {
  position: absolute!important;
  left: -19px!important;
  top: 25px!important;
  height: 14px!important;
  width: 14px!important;
  transform: rotate(45deg)!important;
  background-color: #ffc000!important;
  box-shadow: 0px 4px 4px 0px #aaa!important;
  border-radius: 4px!important;
  content: ""!important;
}

.services-section {
  padding: 5rem!important;
  position: relative!important;
}

.services-section .square {
  height: 100px!important;
  width: 100px!important;
  margin: 30px auto 40px!important;
  position: relative!important;
  border-radius: 15px!important;
  transform: rotate(45deg)!important;
  padding: 10px!important;
  background-color: #ffc000!important;
  box-shadow: 0px 0px 27px 0px #ccc!important;
  border: 2px dotted #eaf3fa!important;
}

.square i {
  transform: rotate(315deg)!important;
  display: block!important;
  color: #eaf3fa!important;
  font-size: 40px!important;
  padding-top: 17px!important;
  padding-right: 14px!important;
}

.services-section .square::before {
  position: absolute!important;
  left: -10px!important;
  top: 0!important;
  width: 1px!important;
  height: 100%!important;
  background-color: #fa9746!important;
  content: ""!important;
}

.services-section .square::after {
  position: absolute!important;
  left: 50px!important;
  top: 66px!important;
  width: 1px!important;
  height: 88%!important;
  background-color: #fa9746!important;
  content: ""!important;
  transform: rotate(90deg)!important;
}

.services-section .serv .num {
  width: 60px!important;
  height: 60px!important;
  background-color: #ffc000!important;
  box-shadow: 0px 0px 15px 0px #ffc000!important;
  margin: -38px auto 15px auto!important;
  border-radius: 50%!important;
  color: #fff!important;
  font-size: 30px!important;
  padding-top: 7px!important;
  border: 1px dashed!important;
}

.services-section .serv h5 span {
  color: black!important;

  margin-top: 1rem!important;
  font-weight: bolder!important;
  font-size: 1.2rem !important;
  text-transform: uppercase!important;
  border-bottom: 3.5px #ffc000 solid!important;
}
.text-c {
    position:relative!important;
    left: 5%;
  }
  .text-c .title {
    color: black!important;

    position: relative!important;
font-size: 1.4rem!important;
    letter-spacing: 1px!important;
    font-weight: bolder!important;
    text-transform: uppercase!important;
  }

  .co {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 3rem!important;
  }
  .serv {
    width: 65%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center!important;
    font-size: 0.85rem!important;
  }

.services-section .serv p {
  color: black!important;
  text-align: center!important;
}

.services-section .serv hr {
  width: 30px!important;
  border-top: 3px solid rgb(0 169 211)!important;
}
@media only screen and (max-width: 1400px) {
  .services-section {
    padding: 2rem!important;
   
  }
  .services-section .serv h5 span {
    color: black!important;

    margin-top: 1rem!important;
    font-weight: bolder!important;
    font-size: 1.1rem !important;
    letter-spacing: 1px!important;
    text-transform: uppercase!important;
    border-bottom: 3.5px #ffc000 solid!important;
  }
.text-c {
    position:relative!important;
    left: 5%!important;
  }
  .text-c .title {
    color: black!important;

    position: relative!important;
font-size: 1.4rem!important;
    letter-spacing: 1px!important;
    font-weight: bolder!important;
    text-transform: uppercase!important;
  }

  .co {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 3rem!important;
  }
  .serv {
    width: 65%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center!important;
    font-size: 0.85rem!important;
  }
}

@media only screen and (max-width: 1200px) {
  .services-section {
    padding: 2rem!important;
   
  }
  .services-section .serv h5 span {
    color: black!important;

    margin-top: 1rem!important;
    font-weight: bolder!important;
    font-size: 1rem !important;
    letter-spacing: 1px!important;
    text-transform: uppercase!important;
    border-bottom: 3.5px #ffc000 solid!important;
  }
.text-c {
    position:relative!important;
    left: 4%!important;
  }
  .text-c .title {
    color: black!important;

    position: relative!important;
font-size: 1.2rem!important;
    letter-spacing: 1px!important;
    font-weight: bolder!important;
    text-transform: uppercase!important;
  }

  .co {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 3rem!important;
  }
  .serv {
    width: 60%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center!important;
    font-size: 0.78rem!important;
  }
}

@media only screen and (max-width: 922px) {
  .services-section .serv h5 span {
    color: black!important;

    margin-top: 1rem!important;
    font-weight: bold!important;
    font-size: 1rem !important;
    letter-spacing: 0.5px!important;
    text-transform: uppercase!important;
  }
  .services-section .co {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 2rem!important;
  }
  .serv {
    width: 97%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center!important;
    font-size: 0.78rem!important;
  }
  .text-c {
    position:relative!important;
    left: 5%!important;
  }
}

@media only screen and (max-width: 768px) {
  .services-section .serv h5 span {
    color: black!important;

    margin-top: 1rem!important;
    font-weight: 600!important;
    font-size: 1rem !important;
    letter-spacing: 0.5px!important;
    text-transform: uppercase!important;
    border-bottom: 2.5px #ffc000 solid!important;
  }

  .co {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 2rem!important;
  }
  .serv {
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center!important;
    font-size: 0.75rem!important;
  }
  .text-c {
    position:relative!important;
    left: 5%!important;
  }
}

@media only screen and (max-width: 567px) {
  .services-section .serv h5 span {
    color: black!important;

    margin-top: 1rem!important;
    font-weight: 600!important;
    font-size: 0.80rem !important;
    letter-spacing: 0.5px!important;
    text-transform: uppercase!important;
    border-bottom: 2.5px #ffc000 solid!important;
  }

  .text-c .title {
    color: black!important;

    
    letter-spacing: 1px!important;
    font-weight: bolder!important;
    text-transform: uppercase!important;
  }
.text-c {
    position:relative!important;
    left: 8%!important;
  }
  .services-section .row {
    padding-top: 0.3rem!important;
  }
 .services-section .col {
    padding-top: 1.5rem!important;
  }
  .serv {
    width: 95% !important;
    font-size: 0.70rem!important;
  }
  .services-section {
    padding: 10px!important;
 
  }
}
