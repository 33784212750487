@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2!important;
  font-family: "Poppins", sans-serif!important;
}

.constructionCover {
  background-image: linear-gradient(
      to bottom left,
      rgb(0, 0, 0),
      rgba(134, 134, 134, 0),
      rgba(134, 134, 134, 0)
    ),
    linear-gradient(
      to right,
      rgba(2, 2, 2, 0.966),
      rgba(37, 37, 37, 0.836),
      rgba(5, 1, 1, 0.315),
      rgba(5, 1, 1, 0.315)
    ),
    url(../../assets/img/construction-equipment-hire.jpg)!important;
  background-position: center center!important;
  background-repeat: no-repeat!important;

  background-size: cover!important;
  min-height: 900px!important;
  margin-top: -10!important;
  opacity: 0.9!important;
  filter: contrast(101%)!important;
  filter: brightness(130%)!important;
  object-fit: scale-down!important;

  background-color: rgba(12, 13, 14, 0.849)!important;
  overflow-x: hidden!important;
  transition: 0.5s!important;
}
.constructionCover {
  display: flex!important;
  align-items: center!important;
}
.constructionContent h1 {
  color: #ffffff !important;
  margin-top: 2rem!important;

  font-size: 1.4rem!important;
  text-transform: uppercase!important;
  letter-spacing: 2px!important;
  text-align: center!important;
  font-weight: 900!important;
}
.constructionContent {
  width: 30%!important;
  margin-left: 8%!important;
}
.constructionCover span{
  border-bottom: solid 4px #d6a614!important;
}
.constructionButton .btn {
  height:2.7rem;
border: 1px solid #d6a614!important;
  border-radius: 4rem!important;
  background-color: #d6a614!important;
  width: 12rem!important;
  font-size: 0.83rem;
 margin-top: 1.5rem!important;
}

.constructionContent p {
  font-size: 0.85rem!important;
  color: #ffffff!important;
  text-align: left!important;
}
@media only screen and (max-width: 1400px) {
  .constructionContent h1 {
    font-size: 1.4rem !important;
    color: #ffffff !important;
    text-align: center !important;
    text-transform: uppercase!important;
    font-weight: bolder !important;
  }
.constructionCover span{
  border-bottom: solid 4px #d6a614!important;
}
  .constructionContent {
    width: 36%!important;
    float: right!important;
    color: #ffffff!important;
    padding-top: 1rem !important;
  }

  .constructionContent p {
    font-size: 0.85rem!important;
  }

  .constructionButton .btn {
    height: 2.7rem!important;
    width: 12rem!important;
    border-radius: 4rem!important;
    margin: 0.3rem!important;
    font-size: 0.83rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }
}
@media only screen and (max-width: 1200px) {
  .constructionContent h1 {
    font-size: 1.3rem !important;
    color: #ffffff !important;

    text-align: center !important;

    font-weight: bolder !important;
  }
.constructionCover span{
  border-bottom: solid 3.5px #d6a614!important;
}
  .constructionContent {
    width: 45% !important;

    color: #ffffff!important;
    padding-top: 1rem !important;
  }

  .constructionContent p {
    font-size: 0.80rem!important;
    text-align: center!important;
  }

  .constructionButton .btn {
    height: 2.4rem!important;
    width: 10rem!important;
    border-radius: 4rem!important;
    margin: 0.3rem!important;
    font-size: 0.75rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }
}

@media only screen and (max-width: 922px) {
 .constructionContent h1 {
    font-size: 1.2rem !important;
    color: #ffffff !important;

    text-align: center !important;

    font-weight: bolder !important;
  }

  .constructionContent {
    width: 45% !important;

    color: #ffffff!important;
    padding-top: 1rem !important;
  }
  .constructionCover span{
  border-bottom: solid 3.2px #d6a614!important;
}
  .constructionContent p {
    font-size: 0.80rem!important;
    text-align: center!important;
  }

  .constructionButton .btn {
    height: 2.3rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.70rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }
}

@media only screen and (max-width: 768px) {
  .constructionContent h1 {
    color: #ffffff !important;
    margin-top: 1rem!important;
    font-size: 1.1rem !important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    text-align: center!important;
    font-weight: bolder!important;
  }
  .constructionCover span{
  border-bottom: solid 3px #d6a614!important;
}
  .constructionContent {
    width: 55%!important;
    margin-left: 8%!important;
    text-align: left!important;
  }

  .constructionButton .btn {
    height: 2.2rem!important;
    width: 9rem!important;
    border-radius: 4rem!important;
    margin: 0.1rem!important;
    font-size: 0.72rem !important;
    text-align: center!important;
    background-color: #d6a614!important;
  }

  .constructionContent p {
    font-size: 0.75rem! important;

    color: #ffffff!important;
  }
}

@media only screen and (max-width: 567px) {
  .constructionCover {
    display: flex!important;
    align-items: center!important;
    justify-items: center!important;
    min-height: auto!important;
  }
  .constructionContent h1 {
    color: #ffffff !important;
    margin-top: 0.5rem!important;

    font-size: 1rem!important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    text-align: center!important;
    font-weight: bolder!important;
  }
  
  .constructionContent {
  width: 90% !important;
margin: auto!important;
padding-top: 20%!important;
padding-bottom:40%!important;
}
  

  .constructionButton .btn {
     font-size: 0.60rem !important;
    font-weight: 400!important;
    height: 2.2rem!important;
    width: 8.5rem!important;
    margin: 0.35rem!important;
    background-color: #d6a614!important;
  }

  .constructionContent p {
    font-size: 0.75rem!important;
    color: #ffffff!important;
  }

  .constructionCover span{
  border-bottom: solid 3px #d6a614!important;
}
}
