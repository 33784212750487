body {
  font-family: poppins;
  scroll-behavior: smooth;
}

img-gradient {
  position: relative;
  display: inline-block;
}
.scroll-down {
  position: absolute;
  bottom: 9%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box span {
  width: 20px;
  height: 20px;
  display: block;
  border-right: 2.5px solid #ffc000;
  border-bottom: 2.5px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.box span:nth-child(2) {
  animation-delay: -0.2s;
}

.box span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: rotate(45deg) translate(10px, 10px);
  }
  100% {
    opacity: 0;
  }
}
/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    rgba(99, 0, 0, 0.5) 0%,
    rgba(125, 153, 0, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(87, 14, 0, 0.5)),
    color-stop(100%, rgba(92, 0, 23, 0.5))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(3, 0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(84, 18, 5, 0.075) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    right,
    rgba(0, 0, 0, 0.329) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(3, 0, 0, 0.5) 0%,
    rgba(32, 0, 0, 0.5) 50%,
    rgba(32, 5, 0, 0.5) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img {
  display: block;
}
.carousel-item {
  min-height: 90vh;
}
.carousel-caption h6{
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing:2px;
  margin-top: 2rem;
  font-weight: 900;
}
.content {
  width:20%;
  margin-bottom:5rem;
}
.carousel-inner {
  background: rgba(37, 37, 37, 0);
}
.carousel-caption p {
  font-size:0.85rem;
}

.carousel-item img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  opacity: 0.9;
}

.slideButton {
  border: 1px solid #ffc000;
  border-radius: 4rem;
  height: 2.7rem;
  width: 12rem;
  background-color: #ffc000;
  font-size: 0.83rem;
}

@media only screen and (max-width: 1400px) {
  .carousel-caption h6 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    margin-top: 1rem !important;
    font-weight: 900 !important;
    display: flex !important;
    align-items: center !important;
  }
  .content {
    width:25%!important;
    margin-bottom:5%!important;
  }
  .carousel-caption p {
    font-size: 0.9rem !important;
  }
  
  .slideButton {
    border: 1px solid #ffc000 !important;
    border-radius: 4rem !important;
    height: 2.7rem !important;
    width: 12rem !important;
    font-weight: 300!important;
    background-color: #ffc000 !important;
    font-size: 0.83rem!important;
  }
  .scroll-down {
    position: absolute;
    bottom: 15%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .carousel-caption h6 {
    font-size: 1.1rem !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    margin-top: 1rem !important;
    font-weight: 900 !important;
    display: flex !important;
    align-items: center !important;
  }
  .content {
    width: 30% !important;
    margin-bottom:5%!important;
  }

  .carousel-caption p {
    font-size: 0.9rem !important;
  }
  .slideButton {
    border: 1px solid #ffc000 !important;
    border-radius: 4rem !important;
    height: 2.7rem !important;
    width: 12rem !important;
    font-weight: 300!important;
    background-color: #ffc000 !important;
    font-size: 0.8rem !important;
  }
  .scroll-down {
    position: absolute;
    bottom: 15%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 922px) {
  .slideButton {
    height: 2.5rem !important;
    width: 10rem !important;
    border-radius: 4rem !important;
    margin: 0.2rem !important;
    font-weight: 250!important;
    font-size: 0.75rem !important;
    text-align: center !important;
    background-color: #ffc000 !important;
  }

  .carousel-item {
    min-height: 90vh !important;
  }
  .carousel-item img {
    width: 100% !important;
    height: 90vh !important;
    object-fit: cover !important;
    opacity: 0.9 !important;
  }
  .carousel-caption h6 {
    font-size: 1.1rem !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    margin-top: 1rem !important;
    font-weight: 900 !important;
    display: flex !important;
    align-items: center !important;
  }
  .content {
    width: 33%!important;
    
  }
  .carousel-caption {
    margin-bottom:12%!important;
    position: absolute !important;
    left:3%!important;
  }
  .carousel-caption p {
    font-size: 0.8rem !important;
  }
  .scroll-down {
    position: absolute;
    bottom: 15%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .slideButton {
    height: 2.2rem;
    width: 9rem;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.70rem !important;
    text-align: center;
    font-weight: 250!important;
    background-color: #ffc000!important;
  }
  .carousel-item {
    min-height: 85vh!important;
  }
  .carousel-item img {
    width: 100%!important;
    height: 85vh!important;
    object-fit: cover!important;
    opacity: 0.9!important;
  }
  .carousel-caption h6 {
    font-size: 0.9rem!important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    margin-top: 1rem!important;
    font-weight: 900!important;
    display: flex!important;
    align-items: center!important;
  }
  .content {
    width:32%!important;
  }
  .carousel-caption {
    margin-bottom:13%!important;
    margin-left: 8%!important;
  }
  .carousel-caption p {
    font-size: 0.78rem!important;
  }
  .scroll-down {
    position: absolute!important;
    bottom: 15% !important;
    left: 50%!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
  }
  .box span {
  width:16px;
  height:16px;
  display:block;
  border-right: 1.5px solid #ffc000;
  border-bottom: 1.5px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
}

@media only screen and (max-width: 567px) {
  .slideButton {
    height: 2.2rem!important;
    width: 8rem!important;
    border-radius: 4rem!important;
    margin: 0.2rem!important;
    font-size: 0.65rem!important;
    font-weight: 250!important;
    text-align: center!important;
  }
  .carousel-item {
    min-height: 95vh!important;
  }
  .carousel-item img {
    width: 100%!important;
    height: 95vh!important;
    object-fit: cover!important;
    opacity: 0.9!important;
  }
  .carousel-caption h6 {
    font-size: 0.80rem!important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    margin-top: 1rem!important;
    font-weight: 900!important;
    display: flex!important;
    align-items: center!important;
  }
  .content {
    width: 50% !important;
padding-bottom: 5rem!important;
  }
  .carousel-caption {
    margin-left: auto!important;
    margin-right: auto!important;
    margin-bottom: 20% !important;
  }
  .carousel-caption p {
    font-size: 0.70rem!important;
    font-weight:100!important;
  }
  .scroll-down {
    position: absolute;
    bottom: 15% !important;
    left: 48%!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
  }
  .box span {
  width: 13px;
  height: 13px;
  display: block;
  border-right: 1px solid #ffc000;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
}

.carousel-indicators {
  visibility: hidden;
}

/* progress bar */

.transition-timer-carousel-progress-bar {
  height: 0.09rem;
  background-color: #ffc000;
  width: 0%;
  margin: 0px 0px 0px 0px;
  border: 1px solid #ffc000;
  border-radius: 4rem;

  position: relative;
}
