@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  
  font-family: "Poppins", sans-serif!important;
}

.sec3 span {
  border-bottom: 5px solid #ffc000;
}
.sec3 p {
  text-align: center;
}

.sec3 .card {
  padding-right: 3rem;
  margin: 1.5rem;
}
.sec3 h2 {
    font-size: 1.4rem;
    font-weight: bolder;
    text-transform: uppercase;
    color: #000;
  }
.sec3 .hd {
  font-size: 25px;
  font-weight: 550;
}

.sec3 .card.hover,
.card:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}
.sec3 .card-body img {
  height: 5rem;
  width: 5rem;
}
.sec3.img {
  margin-bottom: 1rem;
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
}

.sec3 .card-title {
  font-weight: 600;
}

button.focus,
button:focus {
  outline: 0;
  box-shadow: none !important;
}

.rck {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.card-subtitle p {
    font-size: 0.78rem!important;
  }

.card-title {
  font-size: 0.95rem !important;
  font-weight: bold !important;
}

.sec3 h2 {
    font-size: 1.4rem!important;
    font-weight: bolder!important;
    text-transform: uppercase;
  }

@media only screen and (max-width: 1400px) {
  .card-subtitle p {
    font-size: 0.78rem!important;
  }
.card-title {
  font-size: 0.95rem !important;
  font-weight: bold !important;
}
  .sec3 h2 {
    font-size: 1.4rem!important;
    font-weight: bolder!important;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 1200px) {
  .card-subtitle p {
    font-size: 0.76rem!important;
  }

  .card-body img {
    height: 4.5rem!important;
    width: 4.5rem!important;
  }

  .sec3 h2 {
    font-size: 1.2rem!important;
    font-weight: bolder!important;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 922px) {
  .card-subtitle p {
    font-size: 0.75rem!important;
  }

  .card-body img {
    height: 4rem!important;
    width: 4rem!important;
  }

  .sec3 h2 {
    font-size: 1.1rem!important;
    font-weight: bolder;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 768px) {
  .card-subtitle p {
    font-size: 0.75rem!important;
  }

  .card-body img {
    height: 4rem!important;
    width: 4rem!important;
  }

  .sec3 h2 {
    font-size: 1rem!important;
    font-weight: bolder;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 567px) {
  .card-title {
  font-size: 0.75rem !important;
  
}
  .card-subtitle p {
    font-size: 0.65rem!important;
  }

  .card-body img {
    height:3rem!important;
    width:3rem!important;
  }

  .sec3 h2 {
    font-size: 1rem!important;
    font-weight: bolder;
    text-transform: uppercase;
  }
}
