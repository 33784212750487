@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
body{
    background: #f2f2f2!important;
    font-family: 'Poppins', sans-serif!important;
}






.services-page header{
  

  min-width: 100%;
  background-color: black;
  height:23rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-page header h1{
 font-size:1.5rem;
  color:#FFFFFF;
  margin-top: 1rem;
text-align:center;

font-weight: bolder;
  
}

.our-intro{
    font-size: 1rem;
   
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}




@media only screen and (max-width: 1400px){
.services-page header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:23rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.services-page header h1{
 font-size:1.5rem!important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}

.our-intro{
    font-size: 0.85rem!important;
    width: 60%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}

}
@media only screen and (max-width: 1200px) {
.services-page header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.services-page header h1{
 font-size:1.3rem!important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.services-page .our-intro{
    font-size: 0.80rem!important;
   
    width: 65%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}

}


@media only screen and (max-width: 922px) {
.services-page header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.services-page header h1{
 font-size:1.3rem!important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.services-page .our-intro{
    font-size: 0.80rem!important;
   
    width: 71%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}
}

@media only screen and (max-width: 768px) {
.services-page header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:22rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.services-page header h1{
 font-size:1rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.our-intro{
    font-size: 0.75rem!important;
   
    width: 71%!important;
    margin-left: auto!important;
    margin-right: auto!important;
}

}

@media only screen and (max-width: 567px) {
.services-page header{
  

  min-width: 100%!important;
  background-color: black!important;
  height:20rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
.services-page header h1{
 font-size:  1rem !important;
  color:#FFFFFF!important;
  margin-top: 1rem!important;
text-align:center!important;

font-weight: bolder!important;
  
}
.our-intro{
    font-size:0.70rem!important;
 
}

}