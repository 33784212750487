

@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  
  font-family: "Poppins", sans-serif!important;
}
.p1-cover {
    min-height: 60vh!important;
     background-color: white!important;
  
}
.p1-cover h6{
text-transform: uppercase;
font-weight:500!important;
font-size: 1.7rem !important;
}
.p1-cover p{
text-align: left;
}
.p1-content{
    
margin: auto;
width:50%;

}
@media only screen and (max-width: 1400px) { .p1-cover p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .p1-cover p{
        font-size: 0.80rem!important;
    }
}
@media only screen and (max-width: 922px) { .p1-cover p{
        font-size: 0.80rem!important;

    }
    .p1-cover h3{
     text-align: center;
      font-size: 1.5rem!important;
      
 }  
  .p1-cover h6{
text-transform: uppercase;
font-weight:500!important;
font-size: 1.2rem !important;

}
}
@media only screen and (max-width: 768px) {
     .p1-cover p{
        font-size: 0.75rem!important;
    }
}

@media only screen and (max-width: 567px) {
    .p1-cover p{
        font-size: 0.75rem!important;
    }
    
}