@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
 
  font-family: "Poppins", sans-serif!important;
}

 .footer-section ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.single-cta{
  margin:0.9rem ;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text a {
  color: #757575;
}
.cta-text h4 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.95rem;
}
.footer-content {
  position: relative;
  z-index: 2;
}
div.footer-social-icon  #follow-us{
padding-top: 2.5rem!important;
}

.footer-text p {
  margin-bottom: 1rem;
  margin-top: 1rem !;
  font-size: 1rem!;
  
      color: #7e7e7e;
      text-align: center;
  
}
 .use-link{
 padding-top: 1rem;
 padding-bottom: 1rem;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  
  position: relative;
    
}


.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 4px;
  width: 47px;
  background: #ffc000;
}
.footer-social-icon a {
  color: #fff;
  font-size: 0.9rem ;
  margin-right: 2rem;
}
.footer-social-icon i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #ffc000;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.85rem;
color: #878787;
}
.footer-content .space{
  margin-top: 0.7rem;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
 
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 13px 20px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}
.powered{
  font-size: 0.90rem!important;
}
.subscribe-form{
  border-radius: 7rem!important;
  border: solid #ffc000 ;
}
@media only screen and (max-width: 1400px) { .single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.powered{
  font-size: 0.90rem!important;
}
.single-cta{
  margin:0.9rem !important;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.9rem!important;
}
.footer-content {
  position: relative;
  z-index: 2;
}
div.footer-social-icon  #follow-us{
padding-top: 2.5rem!important;
}

.footer-text p {
  margin-bottom: 1rem!important;
  margin-top: 1rem !important;
  font-size: 0.9rem!important;
  
      color: #7e7e7e;
      text-align: left;
  
}
.subscribe-form  input[ placeholder="enter_email_address"]{
font-size: 0.85rem!important;
 }
 .subscribe-form  input {
    font-size: 0.85rem !important;
  }
 
 
 .use-link{
 padding-top: 1rem!important;
 padding-bottom: 1rem!important;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem!important;
  
  position: relative;
    
}


.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 4px;
  width: 47px;
  background: #ffc000;
}

.footer-social-icon i.fa {
  height: 35px!important;
  width: 35px!important;
  text-align: center !important;
  line-height: 38px !important;
  border-radius: 50%!important;
  color: #ffc000 !important;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.80rem!important;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
  display: inline-block;
   width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem!important;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 13px 20px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}}
@media only screen and (max-width: 1200px) {
  .single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.single-cta{
  margin:0.8rem !important;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.95rem!important;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.powered{
  font-size: 0.80rem!important;
}
div.footer-social-icon  #follow-us{
padding-top: 2.5rem!important;
}

.footer-text p {
  margin-bottom: 1rem!important;
  margin-top: 1rem !important;
  font-size: 0.85rem!important;
  
      color: #7e7e7e;
      text-align: left;
  
}
.subscribe-form  input[ placeholder="enter_email_address"]{
font-size: 0.85rem!important;
 }
 .subscribe-form  input {
    font-size: 0.80rem !important;
  }
 
 .use-link{
 padding-top: 1rem!important;
 padding-bottom: 1rem!important;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem!important;
  
  position: relative;
    
}


.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 47px;
  background: #ffc000;
}
.footer-social-icon a {
  color: #fff;
  font-size: 0.8rem !important;
  margin-right: 2rem;
}
.footer-social-icon i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #ffc000;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.85rem!important;

}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
  
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem!important;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 13px 20px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}
}
@media only screen and (max-width: 922px) {
  .single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.single-cta{
  margin:0.8rem !important;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.8rem!important;
}
.footer-content {
  position: relative;
  z-index: 2;
}
div.footer-social-icon  #follow-us{
padding-top: 2.5rem!important;
}

.footer-text p {
  margin-bottom: 1rem!important;
  margin-top: 1rem !important;
  font-size: 0.85rem!important;
  
      color: #7e7e7e;
      text-align: left;
  
}
.subscribe-form  input[ placeholder="enter_email_address"]{
font-size: 0.80rem!important;
 }
 .subscribe-form  input {
    font-size: 0.80rem !important;
  }
 

 .use-link{
 padding-top: 1rem!important;
 padding-bottom: 1rem!important;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem!important;
  
  position: relative;
    
}


.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 47px;
  background: #ffc000;
}
.footer-social-icon a {
  color: #fff;
  font-size: 0.7rem !important;
  margin-right: 2rem;
}
.footer-social-icon i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #ffc000;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.85rem!important;

}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
  
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem!important;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 13px 20px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}
.powered{
  font-size: 0.80rem!important;
}
}
@media only screen and (max-width: 768px) {

.single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.single-cta{
  margin:0.8rem !important;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.8rem!important;
}
.footer-content {
  position: relative;
  z-index: 2;
}
div.footer-social-icon  #follow-us{
padding-top: 2.5rem!important;
}

.footer-text p {
  margin-bottom: 1rem!important;
  margin-top: 1rem !important;
  font-size: 0.75rem!important;
  color: #7e7e7e;
  text-align: left;
  
}
.subscribe-form  input[ placeholder="enter_email_address"]{
font-size: 0.70rem!important;
 }
 .subscribe-form  input {
    font-size: 0.75rem !important;
  }
 

 .use-link{
 padding-top: 2rem!important;
 padding-bottom: 1rem!important;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem!important;
  
  position: relative;
    
}


.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 47px;
  background: #ffc000;
}
.footer-social-icon a {
  color: #fff;
  font-size: 0.7rem !important;
  margin-right: 2rem;
}
.footer-social-icon i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #ffc000;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.85rem!important;
margin-top: 0.5rem !important;
color: #878787;
}
.footer-widget .space {
  margin-top: 0.5rem !important;
  
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
  
  float: left;
  width: 50%;
  margin-bottom: 0.3rem !important;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem!important;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 13px 20px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}
.powered{
  font-size: 0.75rem!important;
}
}
@media only screen and (max-width: 567px) {

.single-cta i {
  color: #ffc000;
  font-size: 1.2rem;
  float: left;
  margin-top: 5px;
}
.single-cta{
  margin:0.8rem !important;
}
.powered{
  font-size: 0.75rem!important;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.cta-text span {
  color: #757575;
  font-size: 0.8rem!important;
}
.footer-content {
  position: relative;
  z-index: 2;
}

.footer-text p {
  margin-bottom: 1rem!important;
  margin-top: 1rem !important;
  font-size: 0.75rem!important;
  
      color: #7e7e7e;
      text-align: left;
  
}
 .use-link{
 padding-top: 1rem!important;
 padding-bottom: 1rem!important;
}
.footer-social-icon h3 {
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem!important;
  
  position: relative;
    
}


.footer-social-icon{
padding-top: 1.5rem!important;
}
.footer-social-icon h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 47px;
  background: #ffc000;
}
.footer-social-icon i{
  color:#ffc000 !important;
  font-size: 0.8rem !important;
  margin-right: 0.5rem!important;
}
.footer-social-icon i {
  height: 35px !important;
  width: 35px!important;
  text-align: center!important;
  line-height: 38px !important;
  border-radius: 50%!important;
  color: #ffc000!important;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
  position: relative;
}
.footer-widget .list-serv{
  font-size: 0.75rem!important;

}
.footer-content fa{
  font-size: 1rem !important;
  
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  height: 3px;
  width: 50px;
  background: #ffc000;
}
.footer-widget ul li {
  
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li:hover{
  color: #ffc000;
}
.footer-widget ul li  {
  color: #878787;
  font-size: 0.9rem!important;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 11px 25px!important;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ffc000;
    padding: 10px 17px;
    border: 1px solid #ffc000;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 1rem!important;
  transform: rotate(-6deg);
}
 .subscribe-form  input[ placeholder="enter_email_address"]{
font-size: 0.70rem!important;
 }
.subscribe-form  input {
    font-size: 0.75rem !important;
  }
 
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 0.8rem;
  color: #878787;
}
.copyright-text p a{
  color: #ffc000;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ffc000;
}
.footer-menu li a {
  font-size: 0.8rem;
  color: #878787;
}
}
