.register-icon {


display: flex;
justify-content: center;

}
.app-work{
    background-color: rgb(255, 255, 255)!important;
}
.app-work p{
        font-size: 0.90rem;
    }
    
.app-work .clie-op{
    margin: 5%;
}
 .app-work h6{
     text-align: center;
 }   
.app-work h3{
     
      font-size: 1.7rem!important;
      font-weight:300!important;
      
 }  
  .app-work h2{
        font-size: 2.4rem!important;
    }
 .app-work col{
     padding:1rem!important;
 }
 .app-work a:hover,
.app-work a:focus {
  color: #FFC000;
}

@media only screen and (max-width: 1400px) { .app-work p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .app-work p{
        font-size: 0.80rem!important;
    }
}
@media only screen and (max-width: 922px) { .app-work p{
        font-size: 0.80rem!important;

    }
    .app-work h3{
     
      font-size: 1.3rem!important;
      
 }  
  .app-work h2{
        font-size: 1.6rem!important;
        margin-top:2rem!important;
        margin-bottom: 2.5rem!important;
        
    }
    .app-work img{
        height: 5rem!important;
        padding-bottom: 0!important;
    }
    .app-work h6{
        margin-top: 0.3rem!important;
    }
    .app-work .it-work{
        margin-bottom: 4rem!important;
    }
}
@media only screen and (max-width: 768px) {
     .app-work p{
        font-size: 0.75rem!important;
    }
}

@media only screen and (max-width: 567px) {
    .app-work p{
        font-size: 0.75rem!important;
    }
}