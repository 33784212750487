

@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  
  font-family: "Poppins", sans-serif!important;
}
.upick-cover {
    min-height: 80vh!important;
     background-color: white!important;
 
}
.upick-cover h6{
text-transform: uppercase;
font-weight:500!important;
font-size: 1.7rem !important;

}
.upick-cover p{
text-align: left;

}
.upick-content{
margin: auto;
width:50%;
}

@media only screen and (max-width: 1400px) { .upick-cover p{
        font-size: 0.85rem!important;
    }
    .upick-content{
margin: auto;
width:60%;
}


}

@media only screen and (max-width: 1200px) { .upick-cover p{
        font-size: 0.80rem!important;
    }
    .upick-content{
margin: auto;
width:65%;
}

}
@media only screen and (max-width: 922px) { .upick-cover p{
        font-size: 0.80rem!important;

    }
   .upick-cover h6{
text-transform: uppercase;
font-weight:500!important;
font-size: 1.2rem !important;

}
     .upick-content{
margin: auto;
width:95%;
}

}
@media only screen and (max-width: 768px) {
     .upick-cover p{
        font-size: 0.75rem!important;
    }
    .upick-cover h6{
text-transform: uppercase;
font-weight:500!important;
font-size: 1.5rem;

}
}

@media only screen and (max-width: 567px) {
    .upick-cover p{
        font-size: 0.75rem!important;
    }
   
     
}