
.signing-cover p {
border-bottom: 1px solid grey;
text-align: left;

}
.card-support{
    height:200px
}

.signing-cover{
    min-height: 80vh!important;

  background-color: #ffff!important;

 
  
}



@media only screen and (max-width: 1400px) { .signing-cover p{
        font-size: 0.85rem!important;
    }

}

@media only screen and (max-width: 1200px) { .signing-cover p{
        font-size: 0.80rem!important;
    }
}
@media only screen and (max-width: 922px) { .signing-cover p{
        font-size: 0.80rem!important;

    }
    .signing-cover h3{
     text-align: center;
      font-size: 1.5rem!important;
      
 }  
}
@media only screen and (max-width: 768px) {
     .signing-cover p{
        font-size: 0.75rem!important;
    }
}

@media only screen and (max-width: 567px) {
    .signing-cover p{
        font-size: 0.75rem!important;
    }
  
}