/* Menu-span Color Overlay (background) */

.navbar {
  width: 100%;
  min-height: 6vh;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  transition: all 0.1s ease-in;
  z-index: inherit 11;
  padding-left: 3rem;
  padding-right: 3rem;
  
}
.navbar .pick {
  text-align: center, Arial;
  color: #FFC000;
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  margin: auto;
  letter-spacing: 2px;
}
.navbar .pick1 {
  text-align: center, Arial;
  color: black;
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  margin: auto;
  letter-spacing: 2px;
}
.scrolled  .pick {
  text-align: center;
  color: #FFC000 !important;
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  margin: auto;
  letter-spacing: 2px;
}
.scrolled  .pick1 {
  text-align: center;
  color: #FFFFFF !important;
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  margin: auto;
  letter-spacing: 2px;
}
.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  z-index: 11;
}
 .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.scrolled .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}


.overlay1 {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: black;

  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 13%;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}


.overlay1 a {
  padding: 8px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size:1.5rem;
  font-weight: 900;
  color: #FFFFFF;
  display: block;
  transition: 0.3s;
}

.overlay1 a:hover,
.overlay1 a:focus {
  color: #FFC000;
}

.overlay1 .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.logo {
  height:2.8rem;
  width: 2.8rem;
}

.navbar a{
  color:#FFC000;
}

.navbar .fa .nav-icon-1{
    font-size: 1.2rem  ;
    color:#FFC000 !important;
  }

  
/*Menu button for opening the drawer*/
.menu {
    cursor: pointer;
    display: inline-block;
   border-radius: 50% ;
   padding-top: 0.6rem;
   padding-bottom: 0.6rem;
   padding-left:1.2rem;
   padding-right:1.2rem;
    font-size: 1.3rem;
    background-color: #FFC000;
    
  }
    .navbar .fa{
    font-size: 1.1rem 
    ;
    
  }

@media only screen and (max-width: 1400px) {
  .logo {
    height: 2.7rem !important;
    width: 2.7rem !important;
  }
  .navbar .fa{
    font-size: 1.2rem !important;
    
  }
  .navbar a{
  color:#FFC000;
}

.overlay1 a:hover,
.overlay1 a:focus {
  color: #FFC000;
}

  .menu {
    cursor: pointer;
    display: inline-block;
   border-radius: 50% !important;
   padding-top: 0.55rem!important;
   padding-bottom: 0.55rem!important;
   padding-left:1.1rem!important;
   padding-right:1.1rem !important;
    font-size: 1.3rem!important;
    background-color: #FFC000!important;
  }
  .overlay1 a {
    padding: 8px!important;
    text-decoration: none!important;
    text-transform: uppercase!important;
    letter-spacing: 2px!important;
    font-size: 1.5rem!important;
    font-weight: 900!important;
    color: #FFFFFF!important;
    display: block!important;
    transition: 0.3s;
  }

  .overlay1 a:hover,
  .overlay1 a:focus {
    color: #FFC000;
  }

  .overlay1 .closebtn {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 3rem !important;
  }
  .navbar .pick {
    text-align: center !important;
    color:#FFC000!important;
    font-size: 1.5rem !important;
    font-style: normal !important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto!important;
    letter-spacing: 2px!important;
  }
  .navbar .pick1 {
    text-align: center!important;
    color:black!important;
    font-size: 1.5rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900!important;
    margin: auto!important;
    letter-spacing: 2px!important;
  }

  .scrolled .pick {
    text-align: center, Arial!important;
    color: #FFFFFF!important;
    font-size: 1.5rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900!important;
    margin: auto!important;
    letter-spacing: 2px !important;
  }
  .scrolled .pick1 {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1.5rem !important; 
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
   .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.scrolled .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
 .overlay-content{
  margin-top:15%!important;
}
.navbar .fa{
    font-size: 1rem !important;
  }
.navbar .faa {
  font-size: 0.85rem !important;
}
.overlay-content{
  margin-top:7%!important;
}
}


@media only screen and (max-width: 1200px) {
 .navbar img {
    height: 2.6rem!important;
    width: 2.6rem!important;
  }
  .navbar .fa{
    font-size: 1rem !important;
  }
   .menu {
    cursor: pointer;
    display: inline-block;
   border-radius: 50% !important;
   padding-top: 0.55rem!important;
   padding-bottom: 0.55rem!important;
   padding-left:1.1rem!important;
   padding-right:1.1rem !important;
    font-size: 1.3rem!important;
    background-color: #FFC000!important;
  }
  .overlay1 a {
    padding: 8px !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    font-size: 1.2rem !important;
    font-weight: 900 !important;
    color: #FFFFFF !important;
    display: block !important;
    transition: 0.3s;
    z-index: 12;
  }

  .overlay1 a:hover,
  .overlay1 a:focus {
    color: #FFC000;
  }

  .overlay1 .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 2.5rem !important;
  }
  .navbar .pick {
    text-align: center!important;
    color: #FFC000 !important;
    font-size: 1.3rem!important;
    font-style: normal!important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto!important;
    letter-spacing: 2px !important;
  }
  .navbar .pick1 {
    text-align: center!important;
    color: black !important;
    font-size: 1.3rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }

  .scrolled .pick {
    text-align: center!important;
    color: #FFFFFF!important;
    font-size: 1.3rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px!important;
  }
  .scrolled  .pick1 {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1.3rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar{
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.scrolled .navbar{
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
 .overlay-content{
  margin-top:10%!important;
}
 .navbar .fa{
    font-size: 1rem !important;
  }
.navbar .faa {
  font-size: 0.85rem !important;
}
}

@media only screen and (max-width: 922px) {
  .navbar img {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
   .menu {
    cursor: pointer !important;
    display: inline-block !important;
    padding-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-top: 0.5rem!important;
    padding-right: 1rem !important;
    background-color: #FFC000 !important;
    border-radius: 50% !important;
    font-size: 1.1rem !important;
  }
  .overlay1 a {
    padding: 8px !important;
    text-decoration: none!important ;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    font-size:1rem !important;
    font-weight: 900 !important;
    color: #FFFFFF !important;
    display: block !important;
    transition: 0.3s;
  }

  .overlay1 a:hover,
  .overlay1 a:focus {
    color: #FFC000;
  }

  .overlay1 .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 2.5rem !important;
  }
  .navbar .pick {
    text-align: center!important;
    color: #FFC000 !important;
    font-size: 1.4rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900!important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar .pick1 {
    text-align: center!important;
    color: black!important;
    font-size: 1.4rem !important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar .fa{
    font-size: 1rem !important;
  }
.navbar .faa {
  font-size: 0.8rem !important;
}
  .scrolled .pick {
    text-align: center!important;
    color: #FFFFFF!important;
    font-size: 1.4rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .scrolled .pick1 {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1.4rem!important;
    font-style: normal !important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.scrolled .navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
 .overlay-content{
  margin-top:10%!important;
}
}
@media only screen and (max-width: 768px) {
  .navbar img {
    height: 2.4rem !important ;
    width: 2.4rem !important;
  }
.navbar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
  .overlay1 a {
    padding: 8px !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    font-size:1rem !important;
    font-weight: 900 !important;
    color: #FFFFFF !important;
    display: block !important;
    transition: 0.3s;
  }

  .overlay1 a:hover,
  .overlay1 a:focus {
    color: #FFC000;
  }

  .overlay1 .closebtn {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 2.5rem !important;
  }
  .overlay-content{
  margin-top:10%!important;
}
  .menu {
    cursor: pointer;
    display: inline-block;
   border-radius: 50% !important;
   padding-top: 0.4rem!important;
   padding-bottom: 0.4rem!important;
   padding-left:0.8rem!important;
   padding-right:0.8rem !important;
    font-size: 1.1rem!important;
    background-color: #FFC000!important;
  }
 

  
  .navbar .pick {
    text-align: center!important;
    color: #FFC000 !important;
    font-size: 1.2rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar .pick1 {
    text-align: center !important;
    color: black !important;
    font-size: 1.2rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
.scrolled .pick {
    text-align: center!important;
    color: #FFFFFF!important;
    font-size: 1.2rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .scrolled .pick1 {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1.2rem!important;
    font-style: normal !important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .navbar{
  padding-left: 0.7rem !important;
  padding-right: 0.7rem !important;
}
.scrolled .navbar{
  padding-left: 0.7rem !important;
  padding-right: 0.7rem !important;
}
.navbar .fa{
  font-size: 0.9rem !important;
}
.navbar .faa {
  font-size: 0.8rem !important;
}
}
@media only screen and (max-width: 567px) {
   .navbar img  {
    height: 1.8rem !important;
    width: 1.8rem !important;
  }
  .navbar .fa {
  font-size: 0.75rem!important;
}
.navbar{
  min-height: 4vh!important;
  padding-left: 0.75rem !important;

  padding-right: 0.75rem !important;
}
  .overlay1 a {
    padding: 0.5rem !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-size: 0.85rem !important;
    font-weight: 900 !important;
    color: #FFFFFF !important;
    display: block !important;
    transition: 0.3s;
  }
.overlay-content{
  margin-top:10%!important;
}
  .overlay1 a:hover,
  .overlay1 a:focus {
    color: #FFC000;
  }

  .overlay1 .closebtn {
    position: absolute;
    top: 15px;
    right: 35px;
    font-size: 2rem !important;
  }
  .menu {
    

    cursor: pointer;
    display: inline-block;
   border-radius: 50% !important;
   padding-top: 0.35rem!important;
   padding-bottom: 0.35rem!important;
   padding-left:0.8rem!important;
   padding-right:0.8rem !important;
    font-size: 1rem!important;
    background-color: #FFC000!important;
  }
  .hide1 {
    visibility: hidden !important;
    display:none !important;
  }

  
  .navbar .pick {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px;
  }
  .navbar .pick1 {
    text-align: center!important;
    color: black !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }

.scrolled .pick {
    text-align: center!important;
    color: #FFFFFF!important;
    font-size: 1rem!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
  .scrolled .pick1 {
    text-align: center !important;
    color: #FFC000 !important;
    font-size: 1rem!important;
    font-style: normal !important;
    font-variant: normal!important;
    font-weight: 900 !important;
    margin: auto !important;
    letter-spacing: 2px !important;
  }
}

@media only screen and (max-width: 320px) {


.logo img {
    height: 2rem !important;
    width: 2rem !important;
  }
.navbar{
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
}


}
@-webkit-keyframes is-active {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes is-active {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.is-active:hover {
  -webkit-animation: is-active 1s ease;
  animation: is-active 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
