@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
body {
  background: #f2f2f2 !important;
  font-family: "Poppins", sans-serif !important;
}

.email:valid {
  border-color: #ffc000;
}
.email:invalid {
  border-color: red;
}

#contact-page form {
  font: 1em sans-serif;
  max-width: 320px;
}

#contact-pagep > label {
  display: block;
}

#contact-pageinput[type="phone"],
#contact-page input[type="email"],
#contact-page textarea,
#contact-page fieldset {
  width: 100%;

  box-sizing: border-box;
}

#contact-page input:invalid {
  box-shadow: 0 0 1px 1px #ffc000;
}

#contact-page input:focus:invalid {
  box-shadow: none;
}

.error {
  color: red;
  size: 80%;
}
.hidden {
  display: none;
}

#contact-page .container {
  margin-top: 50px;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  border-radius: 20px;
}

#contact-page .text-muted {
  font-size: 13px;
  font-weight: bold;
}

#contact-page .form-title {
  font-weight: bold;
}

#contact-page .form-group label {
  font-size: 11px;
  font-weight: bold;
  padding-left: 5px;
}

#contact-page .form-control {
  border: none;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

#contact-page .btn {
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
  background-color: rgba(56, 147, 243, 0.67);
  text-align: center;
  border-radius: 5px;
  margin: 0;
}

#contact-page .form-control:focus {
  color: #495057;
  border-color: #fff !important;
  outline: 0;
  box-shadow: 0 1px 1px 1px rgba(0, 123, 255, 0.25) !important;
}
.contact-button .btn {
  margin-left: 0;
  margin-right: 0;
}

#contact-page img {
  width: 8rem;
  height: 8rem;

  position: relative;
  left: 45%;
}

#contact-page textarea {
  font-size: 0.9rem !important;
}
#contact-page input {
  font-size: 0.9rem !important;
}

#contact-page form {
  width: 85%;
  margin: auto;
}
#contact-page .form-title {
  font-weight: bold;
  font-size: 1.1rem;
}
#contact-page .get-touch {
  width: 50%;
  margin: auto;
}
.contact-text {
  font-size: 0.9rem;
  font-weight: 500;
}
#contact-page .btn {
  width: 6rem;
  font-size: 0.75rem;
  margin-top: 10px;
  height: 2rem;
  text-align: center;
  border-radius: 15px;
  margin: 0;
}
.img-cont {
  margin-top: 15%;
}
@media only screen and (max-width: 1400px) {
  .img-cont {
    margin-top: 9% !important;
  }

  .contact-info {
    position: relative !important;
    left: 2% !important;
  }
  #contact-page img {
    width: 8rem !important;
    height: 8rem !important;

    position: relative !important;
    left: 40% !important;
    margin-bottom: 0.5rem !important;
  }
  #contact-page textarea {
    font-size: 0.9rem !important;
  }
  #contact-page input {
    font-size: 0.9rem !important;
  }

  #contact-page form {
    width: 75% !important;
    margin: auto !important;
  }
  #contact-page .form-title {
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #contact-page .get-touch {
    width: 50% !important;
    margin: auto !important;
  }
  .contact-text {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
  }
  #contact-page .btn {
    width: 6rem !important;
    font-size: 0.75rem !important;
    margin-top: 10px !important;
    height: 2rem !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 1200px) {
  .contact-info {
    position: relative !important;
    left: 1% !important;
  }
  #contact-page img {
    width: 7rem !important;
    height: 7rem !important;
    position: relative !important;
    left: 40% !important;
  }

  #contact-page form {
    width: 70% !important;
    margin: auto !important;
  }
  #contact-page .form-title {
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  .contact-text {
    font-size: 0.85rem !important;
    font-weight: 500 !important;
  }
  #contact-page .btn {
    width: 6rem !important;
    font-size: 0.75rem !important;
    margin-top: 10px !important;
    height: 2rem !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin: 0 !important;
  }
  .img-cont {
    margin-top: 10% !important;
  }
}
@media only screen and (max-width: 922px) {
  .contact-info {
    position: relative !important;
    left: 10% !important;
  }
  #contact-page img {
    width: 5rem !important;
    height: 5rem !important;
    position: relative !important;
    left: 40% !important;
  }

  #contact-page .get-touch {
    width: 70% !important;
    margin: auto !important;
  }
  #contact-page .get-us {
    width: 100% !important;
    margin: auto !important;
  }
  #contact-page form {
    width: 70% !important;
    margin: auto !important;
  }
  #contact-page .form-title {
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  .contact-text {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
  }
  #contact-page .btn {
    width: 6rem !important;
    font-size: 0.75rem !important;
    margin-top: 10px !important;
    height: 2rem !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin: 0 !important;
  }
  .img-cont {
    margin-top: 15% !important;
  }
}

@media only screen and (max-width: 768px) {
  #contact-page img {
    width: 4rem !important;
    height: 4rem !important;
    position: relative !important;
    left: 35% !important;
  }
  #contact-page .get-touch {
    width: 70% !important;
    margin: auto !important;
  }
  #contact-page .get-us {
    width: 100% !important;
    margin: auto !important;
  }
  .img-cont {
    margin-top: 15% !important;
  }
  .contact-info {
    position: relative !important;
    left: 3% !important;
  }
  #contact-page form {
    width: 70% !important;
    margin: auto !important;
  }
  #contact-page .form-title {
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  .contact-text {
    font-size: 0.78rem !important;
    font-weight: 500 !important;
  }
  #contact-page .btn {
    width: 6rem !important;
    font-size: 0.75rem !important;
    margin-top: 10px !important;
    height: 2rem !important;
    text-align: center !important;
    border-radius: 15px!important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 567px) {
  #contact-page img {
    width: 4rem !important;
    height: 4rem !important;
    text-align: center !important;
    position: relative !important;
    left: 2% !important;
  }
  #contact-page textarea {
    font-size: 0.75rem !important;
  }
  #contact-page input {
    font-size: 0.75rem !important;
  }
  .contact-text {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
  }

  #contact-page .form-group label {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    padding-left: 5px !important;
  }
  .contact-info {
    position: relative !important;
    left: 0 !important;
  }

  #contact-page .form-title {
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  .img-cont img {
    text-align: center !important;
    display: block !important;
    margin: auto !important;
    position: relative !important;
    right: 1% !important;
    bottom: 10% !important;
  }
  #contact-page form {
    width: 90% !important;
    margin: auto !important;
  }
  #contact-page .btn {
    width: 6rem !important;
    font-size: 0.75rem !important;
    margin-top: 10px !important;
    height: 2rem !important;
    text-align: center !important;
    border-radius: 15px!important;
    margin: 0 !important;
  }

  #contact-page .get-touch {
    width: 85% !important;
    margin: auto !important;
  }
  #contact-page .get-us {
    width: 85% !important;
    margin: auto !important;
  }
}

.img-cont a {
  color: black !important;
}
